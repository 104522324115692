<template>
  <div class="card">
    <div class="card-body">
      <div class="form-group row">
        <label for="" class="col-sm-1 col-form-label">対象</label>
        <div class="col-sm-1 ">
          <select v-model="type" class="form-control">
            <option value="年別">年別</option>
            <option value="⽉別">⽉別</option>
          </select>
        </div>
        <div class="col-sm-2">
          <select v-model="selectedYear" @change="getSearchedtUsers('year')" class="form-control">
            <option v-for="year in dynamicYears" :key="year" :value="year">{{ year }}</option>
          </select>
        </div>
        <label class="col-sm-1 col-form-label text-sm-end">年</label>
        <div v-if="type == '⽉別'" class="col-sm-1">
          <select v-model="selectedMonth" @change="getSearchedtUsers('month')" class="form-control">
            <option v-for="(month) in months" :key="month" :value="month">{{ month }}</option>
          </select>
        </div>
        <label v-if="type == '⽉別'" class="col-sm-1 col-form-label text-sm-end">月</label>
      </div>

      <div v-if="chartData.labels.length > 0">
        <bar ref="myChart" id="my-chart-id" :options="chartOptions" :data="chartData" />
      </div>

    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-sm-4 col-6">
          <div class="description-block border-right">
            <h5 class="description-header">￥{{ data.premiumsAmount }}</h5>
            <span class="description-text">金額</span>
          </div>
        </div>
        <div class="col-sm-4 col-6">
          <div class="description-block border-right">
            <h5 class="description-header"> {{ data.registrants }}名</h5>
            <span class="description-text">新規登録者数</span>
          </div>
        </div>
        <div class="col-sm-4 col-6">
          <div class="description-block">
            <h5 class="description-header"> {{ data.premiums }}名</h5>
            <span class="description-text">新規課金者数</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">全期間</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-4 col-6">
          <div class="description-block border-right">
            <h5 class="description-header">{{ data.totalRegistrants }}名</h5>
            <span class="description-text">登録者数</span>
          </div>
        </div>
        <div class="col-sm-4 col-6">
          <div class="description-block border-right">
            <h5 class="description-header">{{ data.totalPremiums }}名</h5>
            <span class="description-text">課金者数</span>
          </div>
        </div>
        <div class="col-sm-4 col-6">
          <div class="description-block">
            <h5 class="description-header">￥{{ data.totalAmount }}</h5>
            <span class="description-text">合計金額</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale)
export default {
  components: { Bar },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [ 
          { 
            backgroundColor: 'rgba(0, 0, 255)',
            data: [] 
          } 
        ]
      },
      chartOptions: {
        responsive: true
      },
      data: {
        totalRegistrants: null,
        totalPremiums: null,
        totalAmount: null,
        registrants: null,
        premiums: null,
        premiumsAmount: null,
      },
      type: '年別',
      selectedYear: new Date().getFullYear(),
      selectedMonth: null,
      months: [
        1,2,3,4,5,6,7,8,9,10,11,12
      ],
    }
  },
  computed: {
    dynamicYears() {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let year = 2020; year <= currentYear; year++) {
        years.push(year);
      }
      return years.reverse();
    }
  },
  methods: {
    getSearchedtUsers (search) {
      this.chartData.labels = [];
      this.chartData.datasets[0].data = [];

      let url = null;
      if (search === 'year') {
        url = this.selectedYear + '/null'
      } else {
        url = this.selectedYear + '/' + this.selectedMonth;
      }
      this.$http.get(process.env.VUE_APP_API_PATH + '/admin/billing/' + url)
      .then(response => {
        const responseData = response.data.data;

          this.data.registrants = responseData.registrants;
          this.data.premiums = responseData.premiums;
          this.data.premiumsAmount = responseData.premiums * 500;

          // Extract months and counts from the API response
          const label = responseData.result.map(item => item.label);
          const counts = responseData.result.map(item => item.count);

          // Update the chartData object
          this.chartData.labels = label;
          this.chartData.datasets[0].data = counts;
          this.$refs.myChart.update();
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response);
        }
      });
    },
    getTotalUsers () {
      this.$http.get(process.env.VUE_APP_API_PATH + '/admin/billing')
      .then(response => {
        this.data.totalRegistrants = response.data.data.registrants;
        this.data.totalPremiums = response.data.data.premiums;
        this.data.totalAmount = response.data.data.premiums * 500;
      })
      .catch(error => {
        if (error.response.data.errors) {
          console.log('');
        }
      });
    }
  },
  mounted () {
    this.getTotalUsers();
    this.getSearchedtUsers();
  }
};
</script>

<style>
</style>