<template>
  <div class="card">
    <div class="card-header">
      <button class="btn btn-sm btn-success float-right" @click="this.$router.push({name:'StoreStaffRegisterPage'});">＋スタッフを登録する</button>
      <div>
        <div class="input-group input-group-sm" style="width: 200px;">
          <input type="text" v-model="staffName" class="form-control" placeholder="検索">

          <div class="input-group-append">
            <button type="button" class="btn btn-default" @click="searchStaff">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body table-responsive p-0">
      <table class="table table-hover text-nowrap">
        <thead>
          <tr>
            <th>ID</th>
            <th>名前</th>
            <th>勤務時間</th>
            <th>休憩</th>
            <th>時給</th>
            <th>詳細</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(staff) in data.staff" :key="staff.id">
            <td>{{ staff.id }}</td>
            <td>{{staff.name}}</td>
            <td>{{staff.regular_working_hours_start}} - {{staff.regular_working_hours_stop}}</td>
            <td>{{staff.break_time}}</td>
            <td>￥{{staff.hourly_wage}}</td>
            <td><button class="btn btn-warning" @click="viewDetails(staff.id)">詳細</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: [],
      staffName: null,
    }
  },
  mounted() {
    this.getStaffList();
  },
  methods: {
    getStaffList () {
      const shop_id = this.$store.getters.shop;
      this.$http.get(process.env.VUE_APP_API_PATH + '/shop/staffList/'+shop_id)
      .then(response => {
        if (response.data.success) {
          this.data = response.data.data[0];
        }
      })
      .catch(error => {
        if (error.response) {
          this.$toast.error(error.response.data.message);
        }
      });
    },
    viewDetails (staff_id) {
      this.$router.push({name:'StoreStaffDetailsPage', params: {staff_id: staff_id}});
    },
    searchStaff () {
      const companyId = this.$store.getters.company;
      const shopId = this.$store.getters.shop;
      if (this.staffName) {
        this.$http.get(process.env.VUE_APP_API_PATH + '/shop/staffSearch/'+companyId+'/'+shopId+'/'+this.staffName)
        .then(response => {
          if (response.data.success) {
            this.data.staff = response.data.data;
          }
        })
        .catch(error => {
          if (error.response) {
            this.$toast.error(error.response.data.message);
          }
        });
      } else {
        this.getStaffList();
      }
    }
  },
};
</script>

<style scoped>

</style>