<template>
  <div class="card">
    <div class="card-body">
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">ID</span>
        <div class="col-sm-3">
          <p>{{ data.id }}</p>
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">名前</span>
        <div class="col-sm-3">
          <p>{{ data.name }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">メールアドレス</span>
        <div class="col-sm-3">
          <p>{{ user.email }}</p>
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">パスワード</span>
        <div class="col-sm-3">
          <p>************</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">勤務時間</span>
        <div class="col-sm-3">
          <p>{{ data.regular_working_hours_start }} - {{ data.regular_working_hours_stop }}</p>
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">休憩</span>
        <div class="col-sm-3">
          <p>{{ data.break_time }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">時給</span>
        <div class="col-sm-3">
          <p>￥{{ data.hourly_wage }}</p>
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">有給残日数</span>
        <div class="col-sm-3">
          <p>{{ data.remaining_paid_leave_days }}日</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">年齢</span>
        <div class="col-sm-3">
          <p>{{ data.age }}歳</p>
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">性別</span>
        <div class="col-sm-3">
          <p>{{ data.gender }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">電話番号</span>
        <div class="col-sm-3">
          <p>{{ data.phone_number }}</p>
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">郵便番号</span>
        <div class="col-sm-3">
          <p>{{ data.postal_code }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">住所</span>
        <div class="col-sm-8">
          <p>{{ data.address }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">入社日</span>
        <div class="col-sm-3">
          <p>{{ data.date_of_joining }}</p>
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">有給申請システムの利⽤</span>
        <div class="col-sm-3">
          <p>{{ data.paid_leave ? 'はい' : 'いいえ' }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">タイムカード調整を⾏う</span>
        <div class="col-sm-8">
          <p>{{ data.time_adjustment ? 'はい' : 'いいえ' }}</p>
        </div>
      </div>
    </div>
    <div class="card-footer row">
      <div class="col-sm-4 offset-sm-2">
        <button class="btn btn-primary btn-block" @click="edit">編集</button>
      </div>
      <div class="col-sm-4">
        <button class="btn btn-danger btn-block" @click="dialog">削除</button>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>{{ data.name }}を削除します。</p>
          <p>よろしいですか？</p>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-danger" @click="remove">削除する</button>
          <button type="button" class="btn btn-default" data-dismiss="modal">キャンセル</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      data: [],
      user: [],
    }
  },
  mounted() {
    this.getStaff();
  },
  methods: {
    dialog() {
      $('#modal-default').modal();
    },
    dialogClose() {
      $('#modal-default').modal('hide');
    },
    edit() {
      this.$router.push({name:'StoreStaffEditPage', params: {staff_id: this.$route.params.staff_id}});
    },
    getStaff () {
      const staff_id = this.$route.params.staff_id;
      this.$http.get(process.env.VUE_APP_API_PATH + '/shop/staff/'+staff_id)
      .then(response => {
        if (response.data.success) {
          this.data = response.data.data;
          this.user = this.data.user
        }
      })
      .catch(error => {
        if (error.response) {
          this.$toast.error(error.response.data.message);
        }
      });
    },
    remove () {
      const staff_id = this.$route.params.staff_id;
      this.$http.delete(process.env.VUE_APP_API_PATH + '/shop/staff/'+staff_id)
      .then(response => {
        if (response.data.success) {
          this.dialogClose();
          this.$toast.success(response.data.message);
          setTimeout(() => {
            this.$router.push({name:'CompanyStaffPage'});
          }, 1000);
        }
      })
      .catch(error => {
        if (error.response) {
          this.$toast.error(error.response.data.message);
        }
      });
    }
  },
};
</script>

<style scoped>

</style>