<template>
  <div class="card">
    <div class="card-body">
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">ID</span>
        <div class="col-sm-3">
          <p>{{ staff_id }}</p>
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">名前<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <input type="text" class="form-control" v-model="formData.name">
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">メールアドレス<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <input type="text" class="form-control" v-model="formData.email">
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">パスワード</span>
        <div class="col-sm-3">
          <input type="text" class="form-control" v-model="formData.password">
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">所属店舗</span>
        <div class="col-sm-8">
          <select class="select2" id="shops" multiple="multiple" data-placeholder="所属店舗を選択" style="width: 100%;">
            <option v-for="(shop) in shops" :key="shop.id" :value="shop.id" :selected="filteredShop(shop.id)">{{ shop.shop_name }}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">勤務時間<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <div class="d-flex justify-content-between">
            <input type="time" v-model="formData.regular_working_hours_start" class="form-control" />
            <span class="my-auto mx-2">-</span>
            <input type="time" v-model="formData.regular_working_hours_stop" class="form-control" />
          </div>
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">休憩<span class="required ml-3">*</span></span>
        <div class="col-sm-2">
          <input type="number" v-model="formData.break_time" placeholder="時間単位で⼊⼒" class="form-control" />
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">時給<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <input type="number" class="form-control" v-model="formData.hourly_wage">
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">有給残日数</span>
        <div class="col-sm-3">
          <input type="number" class="form-control" v-model="formData.remaining_paid_leave_days">
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">年齢<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <input type="number" class="form-control" v-model="formData.age">
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">性別<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <select class="form-control" v-model="formData.gender">
            <option value="男">男</option>
            <option value="女">女</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">電話番号<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <input type="" class="form-control" placeholder="ハイフンなし整数で入力してください" v-model="formData.phone_number" @keypress="validateNumber">
        </div>
        <span class="col-sm-2 col-form-span font-weight-bold">郵便番号<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <input type="" class="form-control" placeholder="ハイフンなし整数で入力してください" v-model="formData.postal_code" @keypress="validateNumber">
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">住所<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <textarea class="form-control" cols="30" rows="3" v-model="formData.address"></textarea>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">入社日<span class="required ml-3">*</span></span>
        <div class="col-sm-3">
          <input type="date" v-model="formData.date_of_joining" class="form-control">
        </div>
        <label class="col-sm-2 col-form-label font-weight-bold">有給申請システムの利⽤</label>
        <div class="col-sm-1">
          <div class="form-check mb-3">
            <input type="checkbox" class="form-check-input" v-model="formData.paid_leave">
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 offset-sm-1 col-form-label ">タイムカード調整を⾏う</label>
        <div class="col-sm-1">
          <div class="form-check mb-3">
            <input type="checkbox" class="form-check-input" v-model="formData.time_adjustment">
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer row">
      <div class="col-sm-4 offset-sm-2">
        <button class="btn btn-primary btn-block" @click="dialog">保存</button>
      </div>
      <div class="col-sm-4">
        <button class="btn btn-default btn-block" @click="this.$router.push({name:'CompanyStaffDetailsPage', params: {staff_id: this.$route.params.staff_id}});">キャンセル</button>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>{{ selectedShop }}店の従業員情報を更新します</p>
          <p>よろしいですか？</p>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-primary" @click="register">保存する</button>
          <button type="button" class="btn btn-default" data-dismiss="modal">キャンセル</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      formData: {
        name: null,
        email: null,
        password: null,
        regular_working_hours_start: null,
        regular_working_hours_stop: null,
        break_time: null,
        hourly_wage: null,
        remaining_paid_leave_days: null,
        age: null,
        gender: null,
        phone_number: null,
        postal_code: null,
        address: null,
        date_of_joining: null,
        manager: false,
        time_adjustment: false,
        paid_leave: false,
      },
      staff_id: this.$route.params.staff_id,
      shops: [],
      selectedShop: [],
    }
  },
  computed: {
    filteredShop() {
      return (id) => {
        if (this.formData.shop) {
          return this.formData.shop.find(shop => shop.id === id) ? 1:0;
        } else {
          return 0;
        }
      };
    }
  },
  methods: {
    validateNumber(event) {
      const key = event.key;
      if (!/[0-9]/.test(key)) {
          event.preventDefault();
      }
    },
    initializeSelect2() {
      //Initialize Select2 Elements
      $(".select2").select2({
        language: {
          noResults: function() {
            return "まずはスタッフ⼊⼒を⾏ってください"; // Change this to your desired message
          }
        }
      });

      //Initialize Select2 Elements
      $(".select2bs4").select2({
        theme: "bootstrap4",
      });
    },
    dialog() {
      this.selectedShop = $('#shops option:selected').text();
      $('#modal-default').modal();
    },
    dialogClose() {
      $('#modal-default').modal('hide');
    },
    edit() {
      this.$router.push({name:'CompanyStaffEditPage'});
    },
    getStaff () {
      this.$http.get(process.env.VUE_APP_API_PATH + '/company/staff/'+this.staff_id)
      .then(response => {
        if (response.data.success) {
          this.formData = response.data.data;
          if (response.data.data.time_adjustment > 0) {
            this.formData.time_adjustment = true;
          } else {
            this.formData.time_adjustment = false;
          }

          if (response.data.data.paid_leave > 0) {
            this.formData.paid_leave = true;
          } else {
            this.formData.paid_leave = false;
          }
          this.formData.email = response.data.data.user.email;
        }
      })
      .catch(error => {
        if (error.response) {
          this.$toast.error(error.response.data.message);
        }
      });
    },
    register () {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[#?!@$%^&:*-])[A-Za-z\d#?!@$%^&:*-]{8,16}$/;
      if (this.formData.password) {
        if (!passwordRegex.test(this.formData.password)) {
          this.$toast.error('英⼤⽂字、⼩⽂字、記号（#?!@$%^&*-）、数字を各１以上含む８⽂字以上で作成してください');
          return false;
        }
      } 
      
      this.formData.shop = $('#shops').val();
      this.$http.put(process.env.VUE_APP_API_PATH + '/company/staff/'+this.$route.params.staff_id, this.formData)
      .then(response => {
        if (response.data.success) {
          this.$toast.success(response.data.message);
          setTimeout(() => {
            this.$router.push({name:'CompanyStaffDetailsPage', params: {staff_id: this.$route.params.staff_id}});
          }, 1000);
        }
      })
      .catch(error => {
        if (error.response) {
          const response = error.response;
          if (error.response.status === 422) {
            this.$toast.error('未入力の必須項目があります');
          } else {
            this.$toast.error(response.data.data.error);
          }
        }
      });
      
      this.dialogClose();
    },
    getShopList () {
      const company_id = this.$store.getters.company;
      this.$http.get(process.env.VUE_APP_API_PATH + '/company/shopList/'+company_id)
      .then(response => {
        if (response.data.success) {
          this.shops = response.data.data;
        }
      })
      .catch(error => {
        if (error.response) {
          this.$toast.error(error.response.data.message);
        }
      });
    },
  },
  mounted() {
    this.initializeSelect2();
    this.getStaff();
    this.getShopList();
  },
};
</script>

<style scoped>
  .required {
    font-size: 18px;
    color: #ff0000;
  }
  
  input[type="checkbox"] {
    scale: 1.7;
  }
</style>