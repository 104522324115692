import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/store';

import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import ForgotPassword from '../pages/ForgotPasswordPage';
import ResetPassword from '../pages/ResetPasswordPage';

// Admin Pages
import TopPage from '../pages/admin/TopPage';
import CompanyListPage from '../pages/admin/CompanyListPage';
import PaymentPage from '../pages/admin/PaymentPage';
import AdvertisementPage from '../pages/admin/AdvertisementPage';
import CompanyDetailsPage from '../pages/admin/CompanyDetailsPage';
import CompanyEditPage from '../pages/admin/CompanyEditPage';
import CompanyRegisterPage from '../pages/admin/CompanyRegisterPage';

// Company Pages
import CompanyTopPage from '../pages/company/TopPage';
import CompanyShopPage from '../pages/company/ShopPage';
import CompanyShopDetailsPage from '../pages/company/ShopDetailsPage';
import CompanyShopEditPage from '../pages/company/ShopEditPage';
import CompanyShopRegisterPage from '../pages/company/ShopRegisterPage';
import CompanyStaffPage from '../pages/company/StaffPage';
import CompanyStaffTimeCardPage from '../pages/company/StaffTimeCardPage';
import CompanyStaffDetailsPage from '../pages/company/StaffDetailsPage';
import CompanyStaffEditPage from '../pages/company/StaffEditPage';
import CompanyStaffRegisterPage from '../pages/company/StaffRegisterPage';
import CompanyInfoPage from '../pages/company/CompanyDetailsPage';
import CompanyEditPage2 from '../pages/company/CompanyEditPage';
import SelectedShopPage from '../pages/company/SelectedShopPage';

// Store Pages
import StoreTopPage from '../pages/store/TopPage';
import StoreCalendarPage from '../pages/store/CalendarPage';
import StoreTimeCardPage from '../pages/store/TimeCardPage';
import StoreStaffPage from '../pages/store/StaffPage';
import StoreStaffDetailsPage from '../pages/store/StaffDetailsPage';
import StoreStaffEditPage from '../pages/store/StaffEditPage';
import StoreStaffRegisterPage from '../pages/store/StaffRegisterPage';
import StoreInfoPage from '../pages/store/StoreDetailsPage';

const router = createRouter({
  history: createWebHistory(),
  base: process.env.VUE_APP_PUBLIC_PATH || '/',
  routes: [
    {
      path: '/',
      name: 'Login',
      component: LoginPage,
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPassword,
    },
    {
      path: '/home',
      name: 'Home',
      component: HomePage,
      redirect: {name: 'TopPage'},
      children: [
        {
          path: '/admin',
          name: 'TopPage',
          component: TopPage,
          meta: {
            title: 'TOPページ',
            subTitle: '(管理画面)',
            requiresAuth: true
          },
        },
        {
          path: '/admin/companies',
          name: 'CompanyListPage',
          component: CompanyListPage,
          meta: {
            title: '登録者管理',
            subTitle: '(登録一覧)',
            requiresAuth: true
          },
        },
        {
          path: '/admin/company-register',
          name: 'CompanyRegisterPage',
          component: CompanyRegisterPage,
          meta: {
            title: '登録情報',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/admin/company-details/:company_id',
          name: 'CompanyDetailsPage',
          component: CompanyDetailsPage,
          meta: {
            title: '登録情報',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/admin/company-edit/:company_id',
          name: 'CompanyEditPage',
          component: CompanyEditPage,
          meta: {
            title: '登録情報',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/admin/payment',
          name: 'PaymentPage',
          component: PaymentPage,
          meta: {
            title: '課金情報管理',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/admin/advertisement',
          name: 'AdvertisementPage',
          component: AdvertisementPage,
          meta: {
            title: '広告管理',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/company',
          name: 'CompanyTopPage',
          component: CompanyTopPage,
          meta: {
            title: 'TOPページ',
            subTitle: '(管理画面)',
            requiresAuth: true
          },
        },
        {
          path: '/company/shop-list',
          name: 'CompanyShopPage',
          component: CompanyShopPage,
          meta: {
            title: '店舗一覧',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/company/shop-details/:shop_id',
          name: 'CompanyShopDetailsPage',
          component: CompanyShopDetailsPage,
          meta: {
            title: '店舗詳細',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/company/shop-edit/:shop_id',
          name: 'CompanyShopEditPage',
          component: CompanyShopEditPage,
          meta: {
            title: '店舗詳細',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/company/shop-register',
          name: 'CompanyShopRegisterPage',
          component: CompanyShopRegisterPage,
          meta: {
            title: '店舗登録',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/company/staff-list',
          name: 'CompanyStaffPage',
          component: CompanyStaffPage,
          meta: {
            title: 'スタッフ一覧',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/company/staff-timecard/:staff_id',
          name: 'CompanyStaffTimeCardPage',
          component: CompanyStaffTimeCardPage,
          meta: {
            title: '打刻一覧',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/company/staff-details/:staff_id',
          name: 'CompanyStaffDetailsPage',
          component: CompanyStaffDetailsPage,
          meta: {
            title: 'スタッフ詳細',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/company/staff-edit/:staff_id',
          name: 'CompanyStaffEditPage',
          component: CompanyStaffEditPage,
          meta: {
            title: 'スタッフ編集',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/company/staff-register',
          name: 'CompanyStaffRegisterPage',
          component: CompanyStaffRegisterPage,
          meta: {
            title: 'スタッフ登録',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/company/company-info',
          name: 'CompanyInfoPage',
          component: CompanyInfoPage,
          meta: {
            title: '会社情報',
            subTitle: '(登録情報)',
            requiresAuth: true
          },
        },
        {
          path: '/company/company-edit/:company_id',
          name: 'CompanyEditPage2',
          component: CompanyEditPage2,
          meta: {
            title: '会社情報',
            subTitle: '(登録情報)',
            requiresAuth: true
          },
        },
        {
          path: '/company/selected-shop/:shop_id',
          name: 'SelectedShopPage',
          component: SelectedShopPage,
          meta: {
            title: '',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/store',
          name: 'StoreTopPage',
          component: StoreTopPage,
          meta: {
            title: 'TOPページ',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/store/calendar',
          name: 'StoreCalendarPage',
          component: StoreCalendarPage,
          meta: {
            title: '勤務カレンダー',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/store/timecard',
          name: 'StoreTimeCardPage',
          component: StoreTimeCardPage,
          meta: {
            title: '申請一覧',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/store/staff-list',
          name: 'StoreStaffPage',
          component: StoreStaffPage,
          meta: {
            title: 'スタッフ一覧',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/store/staff-details/:staff_id',
          name: 'StoreStaffDetailsPage',
          component: StoreStaffDetailsPage,
          meta: {
            title: 'スタッフ詳細',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/store/staff-edit/:staff_id',
          name: 'StoreStaffEditPage',
          component: StoreStaffEditPage,
          meta: {
            title: 'スタッフ編集',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/store/staff-register',
          name: 'StoreStaffRegisterPage',
          component: StoreStaffRegisterPage,
          meta: {
            title: 'スタッフ登録',
            subTitle: '',
            requiresAuth: true
          },
        },
        {
          path: '/store/info',
          name: 'StoreInfoPage',
          component: StoreInfoPage,
          meta: {
            title: '店舗情報',
            subTitle: '',
            requiresAuth: true
          },
        },
      ]
    }
  ],
});

  router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
      if (store.getters.isAuthenticated) {
        next();
      } else {
        next({ name: 'Login' });
      }
    } else {
      next();
    }
  })

export default router;
