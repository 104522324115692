<template>
  <div class="card">
    <div class="card-header">
      <button class="btn btn-sm btn-success float-right" @click="this.$router.push({name:'CompanyStaffRegisterPage'});">＋スタッフを登録する</button>
      <div>
        <div class="input-group input-group-sm" style="width: 200px;">
          <input type="text" v-model="staffName" class="form-control" placeholder="検索">

          <div class="input-group-append">
            <button type="button" class="btn btn-default" @click="searchStaff">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body table-responsive p-0">
      <table class="table table-hover text-nowrap">
        <thead>
          <tr>
            <th>ID</th>
            <th>名前</th>
            <th>所属店舗</th>
            <th>タイムカード</th>
            <th>詳細</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(staff) in data" :key="staff.id">
            <td>{{ staff.id }}</td>
            <td>{{ staff.name }}</td>
            <td>
              <p v-for="(shop) in staff.shop" :key="shop.id">{{ shop.shop_name }}</p>
            </td>
            <td><button class="btn btn-info" @click="timeCard(staff.id)">タイムカード</button></td>
            <td><button class="btn btn-warning" @click="viewDetails(staff.id)">詳細</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: [],
      staffName: null,
    }
  },
  mounted() {
    this.getStaffList();
  },
  methods: {
    getStaffList () {
      const company_id = this.$store.getters.company;
      this.$http.get(process.env.VUE_APP_API_PATH + '/company/staffList/'+company_id)
      .then(response => {
        if (response.data.success) {
          this.data = response.data.data;
        }
      })
      .catch(error => {
        if (error.response) {
          this.$toast.error(error.response.data.message);
        }
      });
    },
    viewDetails (staff_id) {
      this.$router.push({name:'CompanyStaffDetailsPage', params: {staff_id: staff_id}});
    },
    searchStaff () {
      if (this.staffName) {
        this.$http.get(process.env.VUE_APP_API_PATH + '/company/staffSearch/'+this.$store.getters.company+'/null/'+this.staffName)
        .then(response => {
          if (response.data.success) {
            this.data = response.data.data;
          }
        })
        .catch(error => {
          if (error.response) {
            this.$toast.error(error.response.data.message);
          }
        });
      } else {
        this.getStaffList();
      }
    },
    timeCard (staff_id) {
      this.$router.push({name:'CompanyStaffTimeCardPage', params: {staff_id:staff_id}});
    },
  },
};
</script>

<style scoped>

</style>