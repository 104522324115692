import { createApp } from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate';
import axios from './axios/axios';
window.$ = window.jQuery = require("jquery");
import router from './router/router';
import Toaster from '@meforma/vue-toaster';
import store from './store/store';
import moment from 'moment';

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {dom, library} from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import {faLock, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faFacebook, faGooglePlus} from '@fortawesome/free-brands-svg-icons';

import "./assets/css/style.css";

//import adminlte scripts
import "../node_modules/admin-lte/plugins/jquery/jquery.min.js"
import "../node_modules/admin-lte/plugins/jquery-ui/jquery-ui.min.js"
import "../node_modules/admin-lte/dist/js/adminlte.min.js"
import "../node_modules/admin-lte/plugins/select2/js/select2.full.min.js"
import "../node_modules/admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js"
import "../node_modules/admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js"
//import "../node_modules/admin-lte/plugins/summernote/summernote-bs4.min.js"
// import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-boots trap-4.min.js"
import "../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.js"
// import "../node_modules/admin-lte/plugins/moment/moment.min.js"
import "../node_modules/admin-lte/plugins/jquery-knob/jquery.knob.min.js"
//import "../node_modules/admin-lte/plugins/jqvmap/maps/jquery.vmap.usa.js"
//import "../node_modules/admin-lte/plugins/jqvmap/jquery.vmap.min.js"
import "../node_modules/admin-lte/plugins/sparklines/sparkline.js"
import "../node_modules/admin-lte/plugins/chart.js/Chart.min.js"
import "../node_modules/admin-lte/plugins/inputmask/jquery.inputmask.min.js"
import "../node_modules/admin-lte/plugins/bootstrap-switch/js/bootstrap-switch.min.js"
import "../node_modules/admin-lte/plugins/bs-stepper/js/bs-stepper.min.js"
import "../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.js"
import "../node_modules/admin-lte/plugins/toastr/toastr.min.js"
import "../node_modules/admin-lte/plugins/bs-custom-file-input/bs-custom-file-input.min.js"

// import('jquery').then(() => {
//   require('tempusdominus-bootstrap-4');
// });

//import adminlte styles
import '../node_modules/admin-lte/dist/css/adminlte.min.css'
import "../node_modules/admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css"
import "../node_modules/admin-lte/plugins/summernote/summernote-bs4.min.css"
import "../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.css"
import "../node_modules/admin-lte/plugins/jqvmap/jqvmap.min.css"
import "../node_modules/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css"
import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css"
import "../node_modules/admin-lte/plugins/bootstrap4-duallistbox/bootstrap-duallistbox.min.css"
import "../node_modules/admin-lte/plugins/bs-stepper/css/bs-stepper.min.css"
import "../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.css"
import "../node_modules/admin-lte/plugins/toastr/toastr.min.css"

import { checkAndSetAuthFromCookie } from './utils/cookieUtils';

library.add(fas, fab, far, faLock, faEnvelope, faFacebook, faGooglePlus);
dom.watch()

const app = createApp(App);
app.config.globalProperties.$http = axios;
app.config.globalProperties.$moment = moment;

const options = {
  position: 'top',
  duration: 2000,
  max: 0,
};

  checkAndSetAuthFromCookie();

  app.component("font-awesome-icon", FontAwesomeIcon);
  app.use(Toaster, options);
  app.use(store);
  app.use(router);
  app.use(Vuelidate);
  app.mount('#app');
