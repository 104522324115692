<template>
  <div class="card">
    <div class="card-body">
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">ID</span>
        <div class="col-sm-8">
          <p>{{ formData.id }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">電子メール</span>
        <div class="col-sm-8">
          <input  v-model="formData.email" type="text" class="form-control" required>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">パスワード</span>
        <div class="col-sm-8">
          <input  v-model="formData.password" type="text" class="form-control" required>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">パスワードを確認する</span>
        <div class="col-sm-8">
          <input  v-model="formData.password_confirmation" type="text" class="form-control" required>
        </div>
      </div>
      <hr class="my-4">
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">プラン<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <span style="color: #47AC24;">{{ formData.plan }}</span> <button class="btn btn-sm btn-warning ml-5" @click="planDialog">プランを変更する</button>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">ステータス<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <select v-model="formData.status" class="form-control" required>
            <option value="退会済">退会済</option>
            <option value="利用中">利用中</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">会社名<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <input  v-model="formData.company_name" type="text" class="form-control" >
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">法人番号</span>
        <div class="col-sm-8">
          <input  v-model="formData.corporate_number" type="text" class="form-control" >
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">代表者名</span>
        <div class="col-sm-8">
          <select v-model="formData.representative_name" class="form-control">
            <option v-for="(staff) in staff" :key="staff.id" :value="staff.name">{{ staff.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">担当者名</span>
        <div class="col-sm-8">
          <select v-model="formData.contact_person_id" class="form-control">
            <option v-for="(staff) in staff" :key="staff.id" :value="staff.id">{{ staff.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">代表店舗</span>
        <div class="col-sm-8">
          <select v-model="formData.shop_id" class="form-control">
            <option v-for="(shop) in formData.shops" :key="shop.id" :value="shop.id">{{ shop.shop_name }}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">所有店舗</span>
        <div class="col-sm-8">
          <div v-for="(shop) in formData.shops" :key="shop.id" class="form-check mb-3">
            <input type="checkbox" 
                    class="form-check-input" 
                    :id="shop.id" 
                    :value="shop.id" 
                    v-model="selectedShops">
            <label class="form-check-label" :for="shop.id">{{ shop.shop_name }}</label>
          </div>
          <!-- <p v-for="(shop) in formData.shops" :key="shop.id">{{ shop.shop_name }}</p> -->
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 offset-sm-1 col-form-label ">アクティブなスタッフ</label>
        <div class="col-sm-8">
          <select class="select2" id="staff" multiple="multiple" data-placeholder="スタッフを選んでください" style="width: 100%;" required>
            <option v-for="(staff) in staff" :key="staff.id" :value="staff.id" :selected="staff.status">
              {{ staff.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">郵便番号<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <input  v-model="formData.postal_code" type="text" placeholder="ハイフンなし整数で入力してください" class="form-control" @keypress="validateNumber" 
           @paste="handlePaste" >
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">住所<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <textarea v-model="formData.address" class="form-control" rows="3"></textarea>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">電話番号<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <input  v-model="formData.phone_number" placeholder="ハイフンなし整数で入力してください" type="text" class="form-control" @keypress="validateNumber" 
           @paste="handlePaste" >
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">URL</span>
        <div class="col-sm-8">
          <input  v-model="formData.url" type="text" class="form-control" >
        </div>
      </div>
    </div>
    <div class="card-footer row">
      <div class="col-sm-4 offset-sm-2">
        <button class="btn btn-primary btn-block" @click="dialog">保存</button>
      </div>
      <div class="col-sm-4">
        <button class="btn btn-default btn-block" @click="this.$router.push({name:'CompanyDetailsPage', params: {company_id: id}});">キャンセル</button>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>{{ formData.company_name }} を保存します。</p>
          <p>よろしいですか？</p>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-primary" @click="register()">保存する</button>
          <button type="button" class="btn btn-default" data-dismiss="modal">キャンセル</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-plan">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center">
          <select v-model="formData.plan" class="form-control">
            <option value="フリー">フリー</option>
            <option value="プレミアム">プレミアム</option>
          </select>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal">保存する</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      formData: {
        plan: null,
        status: null,
        name: null,
        corporate_number: null,
        representative_name: null,
        contact_person_id: null,
        representative_store: null,
        shop_id: null,
        postal_code: null,
        address: null,
        phone_number: null,
        url: null,
        email: null,
        password: null,
        password_confirmation: null,
      },
      company: [],
      shops: [],
      staff: [],
      selectedShops: [],
    }
  },
  mounted () {
    $(".select2").select2({
      language: {
        noResults: function() {
          return "まずはスタッフ⼊⼒を⾏ってください"; // Change this to your desired message
        }
      }
    });
    $(".select2bs4").select2({
      theme: "bootstrap4",
    });

    this.getCompanyDetails();
  },
  methods: {
    validateNumber(event) {
      const key = event.key;
      if (
        event.ctrlKey || event.altKey || key.length > 1 ||
        ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete"].includes(key)
      ) {
        return;
      }
      if (!/[0-9-]/.test(key)) {
        event.preventDefault();
      }
    },
    handlePaste(event) {
      const paste = (event.clipboardData || window.clipboardData).getData('text');
      if (!/^[0-9-]*$/.test(paste)) {
        event.preventDefault();
      }
    },
    dialog() {
      $('#modal-default').modal();
    },
    planDialog() {
      $('#modal-plan').modal();
    },
    dialogClose() {
      $('#modal-default').modal('hide');
    },
    getCompanyDetails () {
      this.$http.get(process.env.VUE_APP_API_PATH + '/admin/company/' + this.$route.params.company_id)
      .then(response => {
        this.formData = response.data;
        this.formData.email = response.data.user.email
        this.shops = this.formData.shops;
        this.staff = this.formData.staff
        this.selectedShops = this.formData.selectedShops;
      })
      .catch(error => {
        console.log(error);
      });
    },
    register() {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[#?!@$%^&:*-])[A-Za-z\d#?!@$%^&:*-]{8,16}$/;
      if (this.formData.password) {
        if (!passwordRegex.test(this.formData.password) && !passwordRegex.test(this.formData.password_confirmation)) {
          this.$toast.error('英⼤⽂字、⼩⽂字、記号（#?!@$%^&*-）、数字を各１以上含む８⽂字以上で作成してください');
          return false;
        }
      } 
      if ($('#staff').val().length > 3 && this.formData.plan === 'フリー') {
        this.$toast.warning('スタッフの最大人数は3人です。');
        return true;
      }
      this.formData.user_id = this.formData.user.id;
      this.formData.selectedShops = this.selectedShops;
      this.formData.activeStaff = $('#staff').val();
      this.$http.put(process.env.VUE_APP_API_PATH + '/admin/company/' + this.$route.params.company_id, this.formData)
      .then(response => {
        this.dialogClose();
        this.$toast.success(response.data.message);
        setTimeout(() => {
          this.$router.push({ name: 'CompanyDetailsPage', params: {company_id: this.$route.params.company_id} });
        }, 1000);
        this.getCompanyDetails();
      })
      .catch(error => {
        const response = error.response.data
          if (response.data.errors.email) {
            this.$toast.error(response.email[0]);
          } else if (response.password) {
            this.$toast.error(response.password[0]);
          } else {
            this.$toast.error(response.data.messages);
          }
      });
    },
  },
};
</script>

<style scoped>
  .required {
    font-size: 18px;
    color: #ff0000;
  }
</style>