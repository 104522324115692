<template>
  <div class="card">
    <div class="card-body">
      <div class="form-group row">
        <label for="" class="col-sm-1 col-form-label">対象</label>
        <div class="col-sm-1 ">
          <select v-model="type" class="form-control">
            <option value="年別">年別</option>
            <option value="⽉別">⽉別</option>
          </select>
        </div>
        <div class="col-sm-2">
          <select v-model="selectedYear" @change="getSearchedAds('year')" class="form-control">
            <option v-for="year in dynamicYears" :key="year" :value="year">{{ year }}</option>
          </select>
        </div>
        <label class="col-sm-1 col-form-label text-sm-end">年</label>
        <div v-if="type == '⽉別'" class="col-sm-1">
          <select v-model="selectedMonth" @change="getSearchedAds('month')" class="form-control">
            <option v-for="(month) in months" :key="month" :value="month">{{ month }}</option>
          </select>
        </div>
        <label v-if="type == '⽉別'" class="col-sm-1 col-form-label text-sm-end">月</label>
      </div>

      <div v-if="chartData.labels.length > 0">
        <bar ref="myChart" id="my-chart-id" :options="chartOptions" :data="chartData" />
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-sm-4 col-6 offset-sm-2">
          <div class="description-block border-right">
            <h5 class="description-header">{{ freeUsers }}名</h5>
            <span class="description-text">広告表示ユーザー数</span>
          </div>
        </div>
        <div class="col-sm-4 col-6">
          <div class="description-block">
            <h5 class="description-header">{{ clicks }}回</h5>
            <span class="description-text">クリック数</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">全期間</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-6 col-6">
          <div class="description-block border-right">
            <h5 class="description-header">{{ totalFreeUsers }}名</h5>
            <span class="description-text">広告表⽰ユーザー数</span>
          </div>
        </div>
        <div class="col-sm-6 col-6">
          <div class="description-block">
            <h5 class="description-header">{{ totalClicks }}回</h5>
            <span class="description-text">総クリック数</span>
          </div>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-sm-12 col-12">
          <button @click="toggleUpload('new', null)"  class="btn btn-default btn-block btn-flat">＋広告をアップロードする</button>
        </div>
      </div>
      <div class="row">
        <div v-for="(ads) in ads" :key="ads.id" class="col-sm-4 col-4">
          <div class="description-block">
            <img :src="ads.image" alt="" width="320" height="50">
            <a href="javascript:void(0)" @click="toggleUpload('edit', ads)"><h5 class="description-header">{{ ads.name }}</h5></a>
            <span class="description-text">再生回数：{{ ads.views }}</span><br>
            <span class="description-text">クリック数：{{ ads.clicks }}</span><br>
            <span class="description-text">配信する：<input type="checkbox" @change="streamAds(ads)" class="form-check-input ml-1" :checked="ads.status ? true:false"></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="exampleInputFile">画像を選択</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" ref="adsImage" :id="'adsImage'" @change="updateImageName($event)">
                    <label class="custom-file-label" :for="'adsImage'">{{ selectedImageName }}</label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleInputFile">広告名</label>
                <input  v-model="data.name" type="text" class="form-control" >
              </div>
              <div class="form-group">
                <label for="exampleInputFile">URL</label>
                <input  v-model="data.url" type="text" class="form-control" placeholder="https://example.com">
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button class="btn btn-success" @click="save">保存</button>
          <button v-if="data.id" class="btn btn-danger" @click="remove(data.id)">削除</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js'
import $ from 'jquery'

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale)
export default {
  components: { Bar },
  data () {
    return {
      chartData: {
        labels: ['1', '2', '3'],
        datasets: [ 
          {
            label: 'Clicks',
            backgroundColor: 'rgba(75, 75, 255)',
            data: []
          },
          // {
          //   label: 'Views',
          //   backgroundColor: 'rgba(150, 150, 225)',
          //   data: []
          // }
        ]
      },
      chartOptions: {
        responsive: true
      },
      selectedImageName: '画像を選択',
      data: {
        image: null,
        name: null,
        url: null,
      },
      ads: [],
      type: '年別',
      selectedYear: new Date().getFullYear(),
      selectedMonth: null,
      months: [
        1,2,3,4,5,6,7,8,9,10,11,12
      ],
      totalFreeUsers: 0,
      totalClicks: 0,
      freeUsers: 0,
      clicks: 0,
    }
  },
  computed: {
    dynamicYears() {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let year = 2020; year <= currentYear; year++) {
        years.push(year);
      }
      return years.reverse();
    }
  },
  methods: {
    toggleUpload (state, ads) {
      this.selectedImageName = '画像を選択';
      this.$refs.adsImage.value = null;
      if (state === 'new') {
        this.data = {
          image: null,
          name: null,
          url: null,
        };
      } else {
        this.data = Object.assign({}, ads);
      }
        $('#modal-default').modal();
    },
    updateImageName (event) {
      const fileInput = event.target;
      if (fileInput.files.length > 0) {
        this.selectedImageName = fileInput.files[0].name;
      } else {
        this.selectedImageName = 'Choose image';
      }
    },
    save () {
      const fileInput = document.getElementById('adsImage');
      if (fileInput.files.length < 1) {
        this.$toast.error('画像を添付してください');
        return false;
      }
      this.data.image = fileInput.files[0];

      // update advertisement
      if (this.data.id) {
        this.data._method = 'PUT';
        this.$http.post(process.env.VUE_APP_API_PATH + '/admin/advertisement/'+this.data.id, this.data,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (response.data.success) {
            this.$toast.success(response.data.message);
            $('#modal-default').modal('hide');
            this.getAds();
          }
        })
        .catch(error => {
          if (error.response) {
            const response = error.response.errors
            this.$toast.error(response.message);
          }
        });
        return true;
      }
      
      // save new advertisement
      this.$http.post(process.env.VUE_APP_API_PATH + '/admin/advertisement', this.data,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      .then(response => {
        if (response.data.success) {
          this.$toast.success(response.data.message);
          $('#modal-default').modal('hide');
          this.getAds();
        }
      })
      .catch(error => {
        if (error.response) {
          const response = error.response.errors
          this.$toast.error(response.message);
        }
      });

    },
    getAds () {
      this.$http.get(process.env.VUE_APP_API_PATH + '/admin/advertisement')
      .then(response => {
        if (response.data.success) {
          this.ads = response.data.data;
        }
      })
      .catch(error => {
        if (error.response) {
          const response = error.response.errors
          this.$toast.error(response.message);
        }
      });
    },
    streamAds (ads) {
      ads.image = null;
      this.$http.put(process.env.VUE_APP_API_PATH + '/admin/advertisement/stream/'+ads.id, ads)
      .then(response => {
        if (response.data.success) {
          this.$toast.success(response.data.message);
          this.getAds();
        }
      })
      .catch(error => {
        if (error.response) {
          const response = error.response.errors
          this.$toast.error(response.error);
        }
      });
    },
    remove (id) {
      this.$http.delete(process.env.VUE_APP_API_PATH + '/admin/advertisement/'+id)
      .then(response => {
        if (response.data.success) {
          this.$toast.success(response.data.message);
          $('#modal-default').modal('hide');
          this.getAds();
        }
      })
      .catch(error => {
        if (error.response) {
          const response = error.response.data;
          this.$toast.error(response.error);
        }
      });
    },
    getTotalAds () {
      this.$http.get(process.env.VUE_APP_API_PATH + '/admin/advertisement/graph')
      .then(response => {
        this.totalFreeUsers = response.data.data.freeUsers;
        this.totalClicks = response.data.data.clicks;
      })
      .catch(error => {
        if (error.response.data.errors) {
          console.log('');
        }
      });
    },
    getSearchedAds (search) {
      this.chartData.labels = [];
      this.chartData.datasets[0].data = [];

      let url = null;
      if (search === 'year') {
        url = this.selectedYear + '/null'
      } else {
        url = this.selectedYear + '/' + this.selectedMonth;
      }
      this.$http.get(process.env.VUE_APP_API_PATH + '/admin/advertisement/graph/' + url)
      .then(response => {
        const responseData = response.data.data;

          this.freeUsers = responseData.freeUsers;
          this.clicks = responseData.clicks;

          // Extract months and counts from the API response
          const label = responseData.resultClick.map(item => item.label);
          const clickCounts = responseData.resultClick.map(item => item.count);
          // const viewCounts = responseData.resultView.map(item => item.count);

          // Update the chartData object
          this.chartData.labels = label;
          this.chartData.datasets[0].data = clickCounts;
          // this.chartData.datasets[1].data = viewCounts;
          this.$refs.myChart.update();
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response);
        }
      });
    },
  },
  mounted () {
    this.getAds();
    this.getTotalAds();
    this.getSearchedAds();
  }
};
</script>

<style>
</style>