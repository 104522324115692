<template>
  
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div v-for="(shop) in shops" :key="shop.id" class="col-sm-4 col-4">
          <div class="description-block">
            <img @click="toggleUpload(shop.id)" :src="shop.shop_image ? shop.shop_image : require('../../assets/img/default-150x150.png')" alt="" width="200">
            <a href="javascript:void(0)" class="nav-link" @click="toggleToShop(shop.id)">
              <h5 class="description-header">{{ shop.shop_name }}</h5>
            </a>
            <span class="description-text">{{ shop.address }}</span><br>
            <span class="description-text">管理者：
              <p v-for="(staff) in filteredStaff(shop)" :key="staff.id">
                {{ staff.name }}
              </p>
            </span>
            <span class="description-text">スタッフ数：{{ shop.staff.length }}名</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="exampleInputFile">Select Image</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" :id="'shopImage-'+this.selectedShop" @change="updateImageName($event)">
                    <label class="custom-file-label" :for="'shopImage-'+this.selectedShop">{{ selectedImageName }}</label>
                  </div>
                  <div class="input-group-append">
                    <button class="input-group-btn btn-success" @click="shopImageUpload">Upload</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'CompanyTopPage',
  data () {
    return {
      data: {
        id : null,
        file: null,
      },
      shops: [],
      selectedShop: null,
      selectedImageName: 'Choose image',
    }
  },
  computed: {
    filteredStaff() {
      return (shop) => {
        return shop.staff.filter((staff) => staff.pivot.manager_flag);
      };
    },
  },
  mounted () {
    this.getShopList();
  },
  methods: {
    viewDetails (param) {
      this.$router.push({name:'SelectedShopPage', params: {shop_id: param}});
    },
    getShopList () {
      const company_id = this.$store.getters.company;
      this.$http.get(process.env.VUE_APP_API_PATH + '/company/shopList/'+company_id)
      .then(response => {
        if (response.data.success) {
          this.shops = response.data.data;
        }
      })
      .catch(error => {
        if (error.response) {
          this.$toast.error(error.response.data.message);
        }
      });
    },
    toggleUpload (shopId) {
      this.selectedShop = shopId
      $('#modal-default').modal();
    },
    shopImageUpload () {
      const fileInput = document.getElementById('shopImage-' + this.selectedShop);
      const file = fileInput.files[0];
      this.data.file = file;
      this.data.id = this.selectedShop;
      this.$http.post(process.env.VUE_APP_API_PATH + '/company/updateShopImage', this.data,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      .then(response => {
        if (response.data.success) {
          this.$toast.success('ショップ画像が更新されました ');
          $('#modal-default').modal('hide');
          this.getShopList();
        }
      })
      .catch(error => {
        if (error.response) {
          const response = error.response.errors
          this.$toast.error(response.message);
        }
      });
    },
    updateImageName (event) {
      const fileInput = event.target;
      if (fileInput.files.length > 0) {
        this.selectedImageName = fileInput.files[0].name;
      } else {
        this.selectedImageName = 'Choose image';
      }
    },
    toggleToShop (shop_id) {
      const data = {
        data: {
          access: 3,
          company_id: this.$store.getters.company,
          shop_id: shop_id,
          token: this.$store.getters.token, 
          userName: this.$store.getters.userName,
        }
      }
      this.$store.dispatch('setAuth', data)
        .then(() => {
              this.$router.push({ name: 'StoreTopPage' });
        });
    }
  },
};
</script>

<style scoped>
  .description-block {
    padding: 20px;
  }
</style>