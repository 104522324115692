<template>
  <div class="card">
    <div class="card-body">
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">電子メール<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <input  v-model="formData.email" type="text" class="form-control" required>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">パスワード<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <input  v-model="formData.password" type="text" class="form-control" required>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">パスワードを確認する<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <input  v-model="formData.password_confirmation" type="text" class="form-control" required>
        </div>
      </div>
      <hr class="my-4">
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">プラン<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <select v-model="formData.plan" class="form-control" required>
            <option value="フリー">フリー</option>
            <option value="プレミアム">プレミアム</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">ステータス<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <select v-model="formData.status" class="form-control" required>
            <option value="退会済">退会済</option>
            <option value="利用中">利用中</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">会社名<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <input  v-model="formData.company_name" type="text" class="form-control" required>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">法人番号</span>
        <div class="col-sm-8">
          <input  v-model="formData.corporate_number" type="text" class="form-control" required>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">郵便番号<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <input  v-model="formData.postal_code" type="text" class="form-control" placeholder="ハイフンなし整数で入力してください" required @keypress="validateNumber" 
           @paste="handlePaste" >
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">住所<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <textarea v-model="formData.address" class="form-control" rows="3" required></textarea>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">電話番号<span class="required ml-3">*</span></span>
        <div class="col-sm-8">
          <input  v-model="formData.phone_number" type="text" class="form-control" placeholder="ハイフンなし整数で入力してください" required @keypress="validateNumber" 
           @paste="handlePaste" >
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">URL</span>
        <div class="col-sm-8">
          <input  v-model="formData.url" type="text" class="form-control" required>
        </div>
      </div>
    </div>
    <div class="card-footer row">
      <div class="col-sm-4 offset-sm-2">
        <button class="btn btn-primary btn-block" @click="dialog">保存</button>
      </div>
      <div class="col-sm-4">
        <button class="btn btn-default btn-block" @click="this.$router.push({name:'CompanyListPage'});">キャンセル</button>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>会社情報を作成します</p>
          <p>よろしいですか？</p>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-primary" @click="register">保存する</button>
          <button type="button" class="btn btn-default" data-dismiss="modal">キャンセル</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      formData: {
        email:null,
        password: null,
        password_confirmation: null,
        plan: null,
        status: null,
        company_name: null,
        corporate_number: null,
        postal_code: null,
        address: null,
        phone_number: null,
        url: null,
      }
    }
  },
  watch: {
    'formData.postal_code'(newValue) {
      if (/e/gi.test(newValue)) {
        this.formData.postal_code = String(newValue).replace(/e/gi, '');
      }
    }
  },
  mounted () {
    $(".select2").select2({
      language: {
        noResults: function() {
          return "まずはスタッフ⼊⼒を⾏ってください"; // Change this to your desired message
        }
      }
    });
    $(".select2bs4").select2({
      theme: "bootstrap4",
    });
  },
  methods: {
    validateNumber(event) {
      const key = event.key;
      if (
        event.ctrlKey || event.altKey || key.length > 1 ||
        ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete"].includes(key)
      ) {
        return;
      }
      if (!/[0-9-]/.test(key)) {
        event.preventDefault();
      }
    },
    handlePaste(event) {
      const paste = (event.clipboardData || window.clipboardData).getData('text');
      if (!/^[0-9-]*$/.test(paste)) {
        event.preventDefault();
      }
    },
    dialog() {
      $('#modal-default').modal();
    },
    dialogClose () {
      $('#modal-default').modal('hide');
    },
    register() {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[#?!@$%^&:*-])[A-Za-z\d#?!@$%^&:*-]{8,16}$/;
      if (!passwordRegex.test(this.formData.password) && !passwordRegex.test(this.formData.password_confirmation)) {
          this.$toast.error('英⼤⽂字、⼩⽂字、記号（#?!@$%^&*-）、数字を各１以上含む８⽂字以上で作成してください');
        } else {
          this.$http.post(process.env.VUE_APP_API_PATH + '/admin/company', this.formData)
          .then(response => {
            this.dialogClose();
            this.$toast.success(response.data.message);
            setTimeout(() => {
              this.$router.push({ name: 'CompanyDetailsPage', params: {company_id: response.data.data.id} });
            }, 1000);
          })
          .catch(error => {
            if (error.response.data.errors) {
              const response = error.response.data.errors
              if (response.email) {
                this.$toast.error(response.email[0]);
              } else if (response.password) {
                this.$toast.error(response.password[0]);
              } else {
                this.$toast.error(response.data.messages);
              }
            }
          });
        }
      
    },
    removeShop(index) {
      this.shops.splice(index, 1);
    },
    addShop() {
      this.shops.push(this.shop);
      this.shop = null;
    },
  },
};
</script>

<style scoped>
  .required {
    font-size: 18px;
    color: #ff0000;
  }
</style>