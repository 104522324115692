<template>
  <div class="login-box">
    <div class="card card-outline card-primary">
      <div class="card-body">
        <p class="login-box-msg">Enter new password</p>

        <form action="/" method="post">
          <div class="input-group mb-3">
            <input :type="showPassword ? 'text' : 'password'" class="form-control" placeholder="Password" v-model="password">
            <div class="input-group-append">
              <div class="input-group-text" @click="togglePasswordVisibility">
                <span class="icon-eye fas fa-eye"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input :type="showPassword ? 'text' : 'password'" class="form-control" placeholder="Confirm Password" v-model="password">
            <div class="input-group-append">
              <div class="input-group-text" @click="togglePasswordVisibility">
                <span class="icon-eye fas fa-eye"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button type="button" class="btn btn-primary btn-block">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: "LoginPage",
    
  data () {
    return {
      username: null,
      password: null,
      showPassword: false,
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
      
      // Get the icon element
      const iconElement = document.querySelector('.icon-eye');

      // Update the class based on showPassword value
      if (this.showPassword) {
        iconElement.classList.remove('fas', 'fa-eye');
        iconElement.classList.add('fas', 'fa-eye-slash');
      } else {
        iconElement.classList.remove('fas', 'fa-eye-slash');
        iconElement.classList.add('fas', 'fa-eye');
      }
    },
  }
  };
  </script>
  
  <style scoped>
  </style>