import router from '../router/router'
import store from '../store/store'

const axios = require('axios')

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

instance.interceptors.request.use(config => {
  const accessToken = store.getters.token;
  if (accessToken) {
    config.headers['Authorization'] = 'Bearer '+accessToken;
  }
  return config
}, function(error) {
  return Promise.reject(error)
})

instance.interceptors.response.use(response => {
    return response
  }, error => {
    if (error.response.status === 401) {
      router.push({ name: 'LoginPage' })
    }
    return Promise.reject(error)
  })

export default instance;
