<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-6">
          <div>
            <div class="input-group input-group-sm" style="width: 200px;">
              <input type="text" v-model="companyName" class="form-control" placeholder="検索">

              <div class="input-group-append">
                <button type="button" class="btn btn-default" @click="search">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-5">
          
        </div>
        <div class="col-md-1">
          <button class="btn btn-sm btn-success float-right" @click="this.$router.push({name:'CompanyRegisterPage'});">新規登録</button>
        </div>
      </div>
    </div>
    <div class="card-body table-responsive p-0">
      <table class="table table-striped table-valign-middle">
        <thead>
          <tr>
            <th>ID</th>
            <th>会社名</th>
            <th>名前</th>
            <th>プラン</th>
            <th>ステータス</th>
            <th>詳細</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(company) in companies" :key="company.id">
            <td>{{ company.id }}</td>
            <td>{{ company.company_name }}</td>
            <td>{{ company.representative_name }}</td>
            <td>{{ company.plan }}</td>
            <td>{{ company.status }}</td>
            <td><button class="btn btn-warning btn-flat btn-sm" @click="showDetails(company.id)">詳細</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
    name: 'CompanyListPage',
    data () {
      return {
        companies: [],
        companyName: null,
        days: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
        selectedDay: null,
      }
    },
    mounted () {
      this.getCompanyList();
    },
    methods: {
      getCompanyList () {
        this.$http.get(process.env.VUE_APP_API_PATH + '/admin/company')
        .then(response => {
          this.companies = response.data.data.companies;
          this.selectedDay = response.data.data.timecard_setting.date_of_lock;

        })
        .catch(error => {
          console.log(error);
        });
      },
      showDetails (id) {
        this.$router.push({ name: 'CompanyDetailsPage', params: {company_id: id} });
      },
      search () {
        if (this.companyName) {
          this.$http.get(process.env.VUE_APP_API_PATH + '/admin/search/'+this.companyName)
          .then(response => {
            if (response.data.success) {
              this.companies = response.data.data;
            }
          })
          .catch(error => {
            if (error.response) {
              this.$toast.error(error.response.data.message);
            }
          });
        } else {
          this.getCompanyList();
        }
      },
    },
}
</script>
<style lang="">
    
</style>