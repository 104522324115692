<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="index3.html" class="brand-link">
      <img src="../assets/logo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
      <span class="brand-text font-weight-light">みんなの勤怠</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <!-- <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img src="../assets/img/avatar5.png" class="img-circle elevation-2" alt="User Image">
        </div>
        <div class="info">
          <a href="#" class="d-block">Anthony Adelantar</a>
        </div>
      </div> -->

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li v-if="userPermission === 1" class="nav-item">
            <router-link to="/home" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                TOPページ
              </p>
            </router-link>
          </li>
          <li v-if="userPermission === 1" class="nav-item">
            <router-link to="/admin/companies" class="nav-link">
              <i class="nav-icon fas fa-users"></i>
              <p>
                登録者管理
              </p>
            </router-link>
          </li>
          <li v-if="userPermission === 1" class="nav-item">
            <router-link to="/admin/payment" class="nav-link">
              <i class="nav-icon fa fa-credit-card"></i>
              <p>
                課金情報管理
              </p>
            </router-link>
          </li>
          <li v-if="userPermission === 1" class="nav-item">
            <router-link to="/admin/advertisement" class="nav-link">
              <i class="nav-icon fas fa-ad"></i>
              <p>
                広告管理
              </p>
            </router-link>
          </li>
          <li v-if="userPermission === 2" class="nav-item">
            <router-link to="/company" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                TOPページ
              </p>
            </router-link>
          </li>
          <li v-if="userPermission === 2" class="nav-item">
            <router-link to="/company/shop-list" class="nav-link">
              <i class="nav-icon fas fa-store"></i>
              <p>
                店舗一覧
              </p>
            </router-link>
          </li>
          <li v-if="userPermission === 2" class="nav-item">
            <router-link to="/company/staff-list" class="nav-link">
              <i class="nav-icon fas fa-users"></i>
              <p>
                スタッフ一覧
              </p>
            </router-link>
          </li>
          <li v-if="userPermission === 2" class="nav-item">
            <router-link to="/company/company-info" class="nav-link">
              <i class="nav-icon fa fa-building"></i>
              <p>
                会社情報
              </p>
            </router-link>
          </li>
          <li v-if="userPermission === 3" class="nav-item">
            <router-link to="/store" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                TOPページ
              </p>
            </router-link>
          </li>
          <li v-if="userPermission === 3" class="nav-item">
            <router-link to="/store/calendar" class="nav-link">
              <i class="nav-icon fas fa-calendar"></i>
              <p>
                勤務カレンダー
              </p>
            </router-link>
          </li>
          <li v-if="userPermission === 3" class="nav-item">
            <router-link to="/store/timecard" class="nav-link">
              <i class="nav-icon fas fa-list"></i>
              <p>
                申請一覧
              </p>
            </router-link>
          </li>
          <li v-if="userPermission === 3" class="nav-item">
            <router-link to="/store/staff-list" class="nav-link">
              <i class="nav-icon fas fa-users"></i>
              <p>
                スタッフ一覧
              </p>
            </router-link>
          </li>
          <li v-if="userPermission === 3" class="nav-item">
            <router-link to="/store/info" class="nav-link">
              <i class="nav-icon fa fa-store"></i>
              <p>
                店舗情報
              </p>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'SideBar',
  computed: {
    ...mapGetters(['userPermission'])
  },
}
</script>

<style>

</style>