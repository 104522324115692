<template>
  <div class="login-box">
    <div class="card card-outline card-primary">
      <div class="card-body">
        <p class="login-box-msg">Input email to reset password</p>

        <form action="/" method="post">
          <div class="input-group mb-3">
            <input type="email" class="form-control" placeholder="Email">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button type="button" class="btn btn-primary btn-block" @click.prevent="redirectToForgotPassword">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: "ForgotPassword",
    
  data () {
    return {
      username: null,
    }
  },
  methods: {
    redirectToForgotPassword() {
      // Navigate to the ForgotPassword component using Vue Router
      this.$router.push({ name: 'ResetPassword' });
    },
  }
  };
  </script>
  
  <style scoped>
  </style>