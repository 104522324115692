<template>
  <div class="card">
    <div class="card-body">
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">店舗名</span>
        <div class="col-sm-8">
          <p>{{ data.shop_name }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">管理者</span>
        <div class="col-sm-8">
          <p v-for="(staff) in filteredStaff" :key="staff.id">
            {{ staff.name }}
          </p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">スタッフ数</span>
        <div class="col-sm-8">
          <p>{{ data.number_of_staff }}名</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">郵便番号</span>
        <div class="col-sm-8">
          <p>{{ data.postal_code }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">住所</span>
        <div class="col-sm-8">
          <p>{{ data.address }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">電話番号</span>
        <div class="col-sm-8">
          <p>{{ data.tel_number }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">URL</span>
        <div class="col-sm-8">
          <p>{{ data.url }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        staff: [],
      },
    }
  },
  computed: {
    filteredStaff() {
      return this.data.staff.filter(staff => staff.pivot.manager_flag);
    },
  },
  mounted() {
    this.getShopDetails();
  },
  methods: {
    getShopDetails () {
      const shop_id = this.$store.getters.shop;
      this.$http.get(process.env.VUE_APP_API_PATH + '/shop/shop/' + shop_id)
      .then(response => {
        this.data = response.data.data;
        this.data.number_of_staff = this.data.staff.length;
      })
      .catch(error => {
        console.log(error);
      });
    },
  },
};
</script>

<style scoped>

</style>