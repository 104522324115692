<template>
  <div class="card">
    <div class="card-body">
      <div class="row mb-5 mt-5">
        <div class="col-sm-12 text-center">
          <h3>{{ data.name }}</h3>
        </div>
        <div class="col-sm-12 text-center">
          <div class="date-navigation d-flex justify-content-center">
            <button class="btn btn-primary btn-flat" :disabled="isButtonDisabled" @click="navigate(-1)">&#60;</button>
            <span class="date-text">{{ dateDisplayed }}</span>
            <button class="btn btn-primary btn-flat" @click="navigate(1)">&#62;</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 table-responsive p-0">
          <table class="table table-hover text-nowrap">
            <thead>
              <tr>
                <th>日付</th>
                <th>店舗</th>
                <th>出勤</th>
                <th>退勤</th>
                <th>休憩</th>
                <th>就業時間</th>
                <th>通常勤務</th>
                <th>残業時間</th>
                <th>備考</th>
                <th>ステータス</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in sortedData" :key="entry.id"  @click="handleRowClick(entry)">
                <td>{{ entry.date }}</td>
                <td>{{ entry.shop }}</td>
                <td v-if="entry.type === 'timecard'">{{ entry.data.time_start }}</td>
                <td v-if="entry.type === 'paid_leave'" colspan="7" style="text-align: center;">{{ '有給休暇' }}</td>
                <td v-if="entry.type === 'timecard'">{{ entry.data.time_stop }}</td>
                <td v-if="entry.type === 'timecard'">{{ entry.data.basic_break_hours }}</td>
                <td v-if="entry.type === 'timecard'">{{ entry.data.working_hours }}</td>
                <td v-if="entry.type === 'timecard'">{{ entry.working_hours }}</td>
                <td v-if="entry.type === 'timecard'">{{ entry.data.overtime_hours ? entry.data.overtime_hours : '00:00' }}</td>
                <td v-if="entry.type === 'timecard'">
                  {{
                    entry.data.notes && entry.data.notes.length
                      ? (entry.data.notes.length <= 10
                        ? entry.data.notes
                        : entry.data.notes.slice(0, 10) + '...')
                      : ''
                  }}
                </td>
                <td>{{ entry.data.approval_status }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="row">
            <div class="col-md-4">
              <p>日付</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.date }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>名前</p>
            </div>
            <div class="col-md-8">
              <p>{{ data.name }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>店舗</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.shop }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>勤務時間</p>
            </div>
            <div class="col-md-8">
              <p>{{ data.regular_working_hours_start }} - {{ data.regular_working_hours_stop }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>出勤</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.time_start }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>退勤</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.time_stop }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>休憩</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecardRecord.basic_break_hours }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>就業時間</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.working_hours }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>通常の勤務時間</p>
            </div>
            <div class="col-md-8">
              <p>{{ data.regular_working_hours }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>残業時間</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.overtime_hours }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>備考</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecardRecord.notes }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>位置情報（出勤）</p>
            </div>
            <div class="col-md-8">
              <p>
                <span v-if="timecardRecord.time_start_latitude">
                  <a target="_blank" :href="'https://www.google.com/maps/search/'+timecardRecord.time_start_latitude+',+'+timecardRecord.time_start_longitude">{{ timecardRecord.time_start_latitude }}, {{ timecardRecord.time_start_longitude }}</a>
                </span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>位置情報（退勤）</p>
            </div>
            <div class="col-md-8">
              <p>
                <span v-if="timecardRecord.time_stop_latitude">
                  <a target="_blank" :href="'https://www.google.com/maps/search/'+timecardRecord.time_stop_latitude+',+'+timecardRecord.time_stop_longitude">{{ timecardRecord.time_stop_latitude }}, {{ timecardRecord.time_stop_longitude }}</a>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal">キャンセル</button>
          <button type="button" class="btn btn-danger" @click="updateApproval(3, timecard.type)">修正を依頼する</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-2">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="row">
            <div class="col-md-4">
              <p>日付</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.date }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>名前</p>
            </div>
            <div class="col-md-8">
              <p>{{ data.name }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>店舗</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.shop }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>勤務時間</p>
            </div>
            <div class="col-md-8">
              <p>{{ data.regular_working_hours_start }} - {{ data.regular_working_hours_stop }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>出勤</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecardRecord.time_start }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>退勤</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecardRecord.time_stop }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>休憩</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecardRecord.basic_break_hours }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>就業時間</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecardRecord.working_hours }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>通常の勤務時間</p>
            </div>
            <div class="col-md-8">
              <p>{{ data.regular_working_hours }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>残業時間</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecardRecord.overtime_hours }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>備考</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecardRecord.notes }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>位置情報（出勤）</p>
            </div>
            <div class="col-md-8">
              <p>
                <span v-if="timecardRecord.time_start_latitude">
                  <a target="_blank" :href="'https://www.google.com/maps/search/'+timecardRecord.time_start_latitude+',+'+timecardRecord.time_start_longitude">{{ timecardRecord.time_start_latitude }}, {{ timecardRecord.time_start_longitude }}</a>
                </span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>位置情報（退勤）</p>
            </div>
            <div class="col-md-8">
              <p>
                <span v-if="timecardRecord.time_stop_latitude">
                  <a target="_blank" :href="'https://www.google.com/maps/search/'+timecardRecord.time_stop_latitude+',+'+timecardRecord.time_stop_longitude">{{ timecardRecord.time_stop_latitude }}, {{ timecardRecord.time_stop_longitude }}</a>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-primary" @click="updateApproval(2, timecard.type)">承認する</button>
          <button type="button" class="btn btn-danger" @click="updateApproval(3, timecard.type)">修正を依頼する</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      data: [],
      timecard: [],
      timecardRecord: [],
      currentDate: new Date(),
      dateDisplayed : null,
      company_plan: 1,
      navigatedDate: this.$moment(),
    }
  },
  mounted() {
    this.getTimecard(this.currentDate.getFullYear()+'-'+(this.currentDate.getMonth()+1).toString().padStart(2, '0')+'-01');
    this.dateDisplayed = this.currentDate.getFullYear() + '年' + (this.currentDate.getMonth() + 1) + '月';
  },
  computed: {
    sortedData() {
      const allData = [];

      // Iterate through your data and push timecard and paid_leave data into allData
      // this.data.forEach(entry => {
        if (this.data.timecard && this.data.timecard.length > 0) {
          this.data.timecard.forEach(timecardEntry => {
            allData.push({ type: 'timecard', date: timecardEntry.date, staff: 'asdaasd', shop: timecardEntry.shop.shop_name, regular_working_hours_start: this.data.regular_working_hours_start, regular_working_hours_stop: this.data.regular_working_hours_stop, working_hours: this.data.regular_working_hours, data: timecardEntry });
          });
        }
        if (this.data.paid_leaves && this.data.paid_leaves.length > 0) {
          this.data.paid_leaves.forEach(paidLeaveEntry => {
            allData.push({ type: 'paid_leave', date: paidLeaveEntry.date, staff: 'asdasd', shop: paidLeaveEntry.shop.shop_name, regular_working_hours_start: this.data.regular_working_hours_start, regular_working_hours_stop: this.data.regular_working_hours_stop, working_hours: this.data.regular_working_hours, data: paidLeaveEntry });
          });
        }
      // });

      // Sort allData by date
      allData.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });
      console.log(allData);
      return allData;
    },
    isButtonDisabled() {
      if (this.company_plan === 0) {
        if (this.$moment().format('YYYY-MM-DD') > this.navigatedDate.format('YYYY-MM-DD')) {
          return true;
        } else {
          if (this.$moment().format('YYYY-MM-DD') > this.navigatedDate.format('YYYY-MM-05')) {
            return true;
          }
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    navigate (direction) {
      this.currentDate.setMonth(this.currentDate.getMonth() + direction);
      this.navigatedDate = this.navigatedDate.clone().add(direction, 'months');
      this.dateDisplayed = this.currentDate.getFullYear() + '年' + (this.currentDate.getMonth() + 1) + '月';
      this.getTimecard(this.currentDate.getFullYear()+'-'+(this.currentDate.getMonth()+1).toString().padStart(2, '0')+'-01');
    },
    getTimecard (date) {
      this.$http.get(process.env.VUE_APP_API_PATH + '/company/timecard/' + this.$route.params.staff_id + '/' + date)
      .then(response => {
        this.data = response.data.data.staff;
        this.company_plan = response.data.data.company_plan[0];
      })
      .catch(error => {
        console.log(error);
      });
    },
    
    viewDetails () {
      this.$router.push({name:'CompanyShopDetailsPage'});
    },
    handleRowClick (data) {
      this.timecard = data;
      this.timecardRecord = data.data;
      console.log(this.timecardRecord);
      if (this.timecard.data.approval_status === '承認済み') {
        $("#modal-1").modal();
      } else if (this.timecard.data.approval_status === '承認待ち') {
        $("#modal-2").modal();
      }
    },
    updateApproval (status, type) {
      const data = {
        approval_status: status
      };
      if (type === 'timecard') {
        this.$http.put(process.env.VUE_APP_API_PATH + '/company/timecard/' + this.timecardRecord.id, data)
        .then(response => {
          if (response.data.success) {
            this.$toast.success(response.data.message);
            this.getTimecard(this.currentDate.getFullYear()+'-'+(this.currentDate.getMonth()+1).toString().padStart(2, '0')+'-'+this.currentDate.getDate());
          }
        })
        .catch(error => {
          if (error.response) {
            const response = error.response.errors
            this.$toast.error(response);
          }
        });
      } else {
        this.$http.put(process.env.VUE_APP_API_PATH + '/shop/paidLeave/' + this.timecardRecord.id, data)
        .then(response => {
          if (response.data.success) {
            this.$toast.success(response.data.message);
            this.getTimecard(this.currentDate.getFullYear()+'-'+(this.currentDate.getMonth()+1).toString().padStart(2, '0')+'-'+this.currentDate.getDate());
          }
        })
        .catch(error => {
          if (error.response) {
            const response = error.response.errors
            this.$toast.error(response);
          }
        });
      }
      $("#modal-1").modal('hide');
      $("#modal-2").modal('hide');
    },
  },
};
</script>

<style scoped>
  .date-navigation {
    display: flex;
    align-items: center;
  }

  .arrow-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .date-text {
    margin: 0 10px;
    font-size: 18px;
    font-weight: bold;
  }

</style>