<template>
  <div class="card">
    <div class="card-body">
      <div class="form-group row">
        <span class="col-sm-1 col-form-span font-weight-bold">店舗名</span>
        <div class="col-sm-3">
          <p>{{ data.shop_name }}</p>
        </div>
        <span class="col-sm-1 col-form-span font-weight-bold">管理者</span>
        <div class="col-sm-3">
          <p v-for="(staff) in filteredStaff" :key="staff.id">
            {{ staff.name }}
          </p>
        </div>
        <span class="col-sm-1 col-form-span font-weight-bold">スタッフ数</span>
        <div class="col-sm-3">
          <p>{{ data.number_of_staff }}名</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-1 col-form-span font-weight-bold">勤務状況</span>
        <div class="col-sm-3">
          <span>&nbsp;</span>
          <span class="ml-5">&nbsp;</span>
        </div>
      </div>
      <div class="row mt-5">
        
        <div v-for="(timecard) in getTimecardWithStaffNames" :key="timecard.id" class="col-md-3">
          <div class="card bg-default">
            <div class="card-body">
              <div class="text-center">{{ timecard.staffName }}</div>
              <div class="mt-5">
                <span>{{ timecard.time_start }}</span>
                <span class="float-right">{{ timecard.time_stop }}</span>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        staff: [],
        timecard: [],
      },
    }
  },
  computed: {
    filteredStaff() {
      return this.data.staff.filter(staff => staff.pivot.manager_flag);
    },
    getTimecardWithStaffNames() {
      return this.data.timecard.map(timecard => {
        const staffInfo = this.data.staff.find(staff => staff.id === timecard.staff_id);
        const staffName = staffInfo ? staffInfo.name : 'Unknown';
        return {
          ...timecard,
          staffName
        };
      });
    }
  },
  mounted() {
    this.getShopDetails();
  },
  methods: {
    getShopDetails () {
      this.$http.get(process.env.VUE_APP_API_PATH + '/company/timecardList/' + this.$route.params.shop_id)
      .then(response => {
        this.data = response.data.data;
        this.data.number_of_staff = this.data.staff.length;
      })
      .catch(error => {
        console.log(error);
      });
    },
  },
};
</script>

<style scoped>
  .bg-warning {
      color: #FFF !important;
  }
</style>