<template>
  <div class="card">
    <div v-if="company" class="card-body">
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">ID</span>
        <div class="col-sm-8">
          <p>{{ company.id }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">電子メール</span>
        <div class="col-sm-8">
          <p>{{ company.user.email }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">プラン</span>
        <div class="col-sm-8">
          <p style="color: #47AC24;">{{ company.plan }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">ステータス</span>
        <div class="col-sm-8">
          <p style="color: #0019FF;">{{ company.status }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">会社名</span>
        <div class="col-sm-8">
          <p>{{ company.company_name }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">法人番号</span>
        <div class="col-sm-8">
          <p>{{ company.corporate_number }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">代表者名</span>
        <div class="col-sm-8">
          <p>{{ company.representative_name }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">担当者名</span>
        <div class="col-sm-8">
          <p>{{ company.staff_name ? company.staff_name: '&nbsp;' }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">代表店舗</span>
        <div class="col-sm-8">
          <p>{{ company.shop_name }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">所有店舗</span>
        <div class="col-sm-8">
          <p v-for="(shop) in company.activeShop" :key="shop.id">{{ shop.shop_name }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">アクティブなスタッフ</span>
        <div class="col-sm-8">
          <p v-for="(staff) in company.activeStaff" :key="staff.id">{{ staff.name }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">郵便番号</span>
        <div class="col-sm-8">
          <p>{{ company.postal_code }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">住所</span>
        <div class="col-sm-8">
          <p>{{ company.address }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">電話番号</span>
        <div class="col-sm-8">
          <p>{{ company.phone_number }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">URL</span>
        <div class="col-sm-8">
          <p>{{ company.url }}</p>
        </div>
      </div>
    </div>
    <div class="card-footer row">
      <div class="col-sm-4 offset-sm-2">
        <button class="btn btn-primary btn-block" @click="edit">編集</button>
      </div>
      <div class="col-sm-4">
        <button class="btn btn-danger btn-block" @click="deleteDialog">削除</button>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>{{ company.company_name }}を削除します。</p>
          <p>よろしいですか？</p>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-danger" @click="remove">削除する</button>
          <button type="button" class="btn btn-default" data-dismiss="modal">キャンセル</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      company: [],
    }
  },
  mounted () {
    this.getCompanyDetails();
  },
  methods: {
    getCompanyDetails () {
      this.$http.get(process.env.VUE_APP_API_PATH + '/admin/company/' + this.$route.params.company_id)
      .then(response => {
        this.company = response.data;
        const shop = this.company.shops.find(shop => shop.id === this.company.shop_id);
        this.company.shop_name = shop.shop_name;
        const staff = this.company.staff.find(staff => staff.id === this.company.contact_person_id);
        this.company.staff_name = staff.name;
      })
      .catch(error => {
        console.log(error);
      });
    },
    deleteDialog() {
      $('#modal-default').modal();
    },
    dialogClose() {
      $('#modal-default').modal('hide');
    },
    edit() {
      this.$router.push({name:'CompanyEditPage'});
    },
    remove () {
      this.$http.delete(process.env.VUE_APP_API_PATH + '/admin/company/' + this.$route.params.company_id)
      .then(response => {
        this.dialogClose();
        this.$toast.success(response.data.message);
        setTimeout(() => {
          this.$router.push({name:'CompanyListPage'});
        }, 1000);
      })
      .catch(error => {
        console.log(error);
      });
    }
  },
};
</script>

<style scoped>

</style>