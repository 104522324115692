<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">新着情報</h3>
    </div>
    <div class="card-body p-0">
      <ul class="products-list product-list-in-card pl-2 pr-2">
        <li v-for="(data) in data" :key="data.id">
          {{ data.message }}
        </li>
      </ul>
    </div>
    <div class="card-footer text-center">
      <a href="javascript:void(0)" class="uppercase" @click="getAllNotification">すべて表示する</a>
    </div>
  </div>
  <div class="d-flex justify-content-between">
    <router-link to="/admin/companies" class="btn btn-app bg-primary">登録者管理</router-link>
    <router-link to="/admin/payment" class="btn btn-app bg-info">課金情報管理</router-link>
    <router-link to="/admin/advertisement" class="btn btn-app bg-warning">広告管理</router-link>
  </div>
</template>

<script>
  export default {
    name: 'AdminTopPage',
    data () {
      return {
        data: [],
      }
    },
    mounted () {
      this.getNotification();
    },
    methods: {
      getNotification () {
        this.$http.get(process.env.VUE_APP_API_PATH + '/admin/notification/initial')
        .then(response => {
          this.data = response.data.data;

        })
        .catch(error => {
          console.log(error);
        });
      },
      getAllNotification () {
        this.$http.get(process.env.VUE_APP_API_PATH + '/admin/notification')
        .then(response => {
          this.data = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
      },
    }
  };
</script>

<style scoped>
  .justify-content-between {
    padding: 50px;
  }
  .btn-app {
    padding: 50px;
    padding-bottom: 70px;
    text-align: center;
    font-size: 16px;
  }
  .btn {
    color: #FFF !important;
  }
</style>