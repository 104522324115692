<template>
  <div class="card">
    <div class="card-body">
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">店舗名</span>
        <div class="col-sm-8">
          <p>{{ data.shop_name }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">管理者</span>
        <div class="col-sm-8">
          <p v-for="(staff) in filteredStaff" :key="staff.id">
            {{ staff.name }}
          </p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">スタッフ数</span>
        <div class="col-sm-8">
          <p>{{ data.number_of_staff }}名</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">郵便番号</span>
        <div class="col-sm-8">
          <p>{{ data.postal_code }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">住所</span>
        <div class="col-sm-8">
          <p>{{ data.address }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">電話番号</span>
        <div class="col-sm-8">
          <p>{{ data.tel_number }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">URL</span>
        <div class="col-sm-8">
          <p>{{ data.url }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">タイムカード調整</span>
        <div class="col-sm-8">
          <p>{{ data.time_adjustment }}</p>
        </div>
      </div>
    </div>
    <div class="card-footer row">
      <div class="col-sm-4 offset-sm-2">
        <button class="btn btn-primary btn-block" @click="edit">編集</button>
      </div>
      <div class="col-sm-4">
        <button class="btn btn-danger btn-block" @click="dialog">削除</button>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>{{ data.shop_name }}を削除します。</p>
          <p>よろしいですか？</p>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-danger" @click="remove">削除する</button>
          <button type="button" class="btn btn-default" data-dismiss="modal">キャンセル</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      data: {
        staff: [],
      },
    }
  },
  computed: {
    filteredStaff() {
      return this.data.staff.filter(staff => staff.pivot.manager_flag);
    },
  },
  mounted() {
    this.getShopDetails();
  },
  methods: {
    getShopDetails () {
      this.$http.get(process.env.VUE_APP_API_PATH + '/company/shop/' + this.$route.params.shop_id)
      .then(response => {
        this.data = response.data.data;
        this.data.number_of_staff = this.data.staff.length;
      })
      .catch(error => {
        console.log(error);
      });
    },
    dialog () {
      $('#modal-default').modal();
    },
    dialogClose () {
      $('#modal-default').modal('hide');
    },
    edit () {
      this.$router.push({name:'CompanyShopEditPage', params: {shop_id: this.$route.params.shop_id}});
    },
    remove () {
      this.$http.delete(process.env.VUE_APP_API_PATH + '/company/shop/' + this.$route.params.shop_id)
      .then(response => {
        this.dialogClose();
        this.$toast.success(response.data.message);
        setTimeout(() => {
          this.$router.push({name:'CompanyShopPage'});
        }, 1000);
      })
      .catch(error => {
        console.log(error);
      });
    }
  },
};
</script>

<style scoped>

</style>