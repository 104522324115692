<template>
  <div class="card">
    <div class="card-body">
      <div class="form-group row">
        <label class="col-sm-2 offset-sm-1 col-form-label ">店舗名<span class="required ml-3">*</span></label>
        <div class="col-sm-8">
          <input type="text" class="form-control" v-model="formData.shop_name" required>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 offset-sm-1 col-form-label ">管理者<span class="required ml-3">*</span></label>
        <div class="col-sm-8">
          <select class="select2" id="managers" multiple="multiple" data-placeholder="スタッフを選んでください" style="width: 100%;" required>
            <option v-for="(staff) in formData.staff" :key="staff.id" :value="staff.id" :selected="staff.pivot.manager_flag">
              {{ staff.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 offset-sm-1 col-form-label ">スタッフ数</label>
        <div class="col-sm-8">
          <p>{{ formData.number_of_staff }}名</p>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 offset-sm-1 col-form-label ">郵便番号<span class="required ml-3">*</span></label>
        <div class="col-sm-8">
          <input class="form-control" placeholder="ハイフンなし整数で入力してください" v-model="formData.postal_code" required @keypress="validateNumber">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 offset-sm-1 col-form-label ">住所<span class="required ml-3">*</span></label>
        <div class="col-sm-8">
          <textarea class="form-control" cols="30" rows="3" v-model="formData.address" required></textarea>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 offset-sm-1 col-form-label ">電話番号<span class="required ml-3">*</span></label>
        <div class="col-sm-8">
          <input class="form-control" placeholder="ハイフンなし整数で入力してください" v-model="formData.tel_number" required @keypress="validateNumber">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 offset-sm-1 col-form-label ">URL</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" v-model="formData.url" required>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 offset-sm-1 col-form-label ">タイムカード調整</label>
        <div class="col-sm-1">
          <div class="form-check mb-3">
            <input type="checkbox" class="form-check-input" v-model="time_adjustment">
            <label class="form-check-label time_adjustment" for="time_adjustment">利⽤する</label>
          </div>
        </div>
        <div class="col-sm-1" v-if="time_adjustment">
          <div class="form-check mb-2">
            <select v-model="formData.time_adjustment" class="form-control form-control-sm" placeholder="分" required>
              <option value="5">5</option>
              <option value="15">15</option>
              <option value="30">30</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer row">
      <div class="col-sm-4 offset-sm-2">
        <button class="btn btn-primary btn-block" @click="dialog">保存</button>
      </div>
      <div class="col-sm-4">
        <button class="btn btn-default btn-block" @click="this.$router.push({name:'CompanyShopDetailsPage', params: {shop_id: this.$route.params.shop_id}});">キャンセル</button>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>{{ formData.shop_name }}を保存します。</p>
          <p>よろしいですか？</p>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-primary" @click="update">保存する</button>
          <button type="button" class="btn btn-default" data-dismiss="modal">キャンセル</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      formData: {
        shop_name: null,
        managers: [],
        number_of_staff: 0,
        postal_code: null,
        address: null,
        tel_number: null,
        url: null,
        company_id: this.$store.getters.company,
        time_adjustment: 0,
      },
      staff: [],
      time_adjustment: false,
    }
  },
  methods: {
    validateNumber(event) {
      const key = event.key;
      if (!/[0-9]/.test(key)) {
          event.preventDefault();
      }
    },
    initializeSelect2() {
      //Initialize Select2 Elements
      $(".select2").select2({
        language: {
          noResults: function() {
            return "まずはスタッフ⼊⼒を⾏ってください"; // Change this to your desired message
          }
        }
      });

      //Initialize Select2 Elements
      $(".select2bs4").select2({
        theme: "bootstrap4",
      });
    },
    dialog() {
      $('#modal-default').modal();
    },
    dialogClose () {
      $('#modal-default').modal('hide');
    },
    getShopDetails () {
      this.$http.get(process.env.VUE_APP_API_PATH + '/company/shop/' + this.$route.params.shop_id)
      .then(response => {
        this.formData = response.data.data;
        if (this.formData.time_adjustment > 1) {
          this.time_adjustment = true;
        }
        this.formData.number_of_staff = this.formData.staff.length;
      })
      .catch(error => {
        console.log(error);
      });
    },
    update () {
      this.formData.company_id = this.$store.getters.company;
      this.formData.managers = $('#managers').val();
      if (!this.time_adjustment) {
        this.formData.time_adjustment = 0;
      }
      this.$http.put(process.env.VUE_APP_API_PATH + '/company/shop/'+this.$route.params.shop_id, this.formData)
      .then(response => {
        if (response.data.success) {
          this.$toast.success(response.data.message);
          setTimeout(() => {
            this.$router.push({ name: 'CompanyShopDetailsPage', params: {shop_id: this.$route.params.shop_id} });
          }, 1500);
        }
      })
      .catch(error => {
        if (error.response) {
          const response = error.response.data;
          if (error.response.status === 422) {
            this.$toast.error('未入力の必須項目があります');
          } else {
            this.$toast.error(response.message);
          }
        }
      });
      this.dialogClose();
    },
  },
  mounted() {
    this.initializeSelect2();
    this.getShopDetails();
  }
};
</script>

<style scoped>
  .required {
    font-size: 18px;
    color: #ff0000;
  }
  
  input[type="checkbox"] {
    margin-top: 12px;
    scale: 1.7;
  }

  .form-check-label {
    margin-top: 5px;
  }
</style>