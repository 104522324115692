<template>
  <div class="card">
    <div class="card-body">
      <div class="row mb-5 mt-5">
        <div class="col-sm-12 text-center">
          <div class="date-navigation d-flex justify-content-center">
            <button class="btn btn-primary btn-flat" :disabled="isButtonDisabled" @click="navigate(-1)">&#60;</button>
            <span class="date-text">{{ dateDisplayed }}</span>
            <button class="btn btn-primary btn-flat" @click="navigate(1)">&#62;</button>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="input-group input-group-sm" style="width: 200px;">
          <input type="text" v-model="name" class="form-control" placeholder="検索">

          <div class="input-group-append">
            <button type="button" @click="search(null)" class="btn btn-default">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 table-responsive p-0">
          <table class="table table-hover text-nowrap">
            <thead>
              <tr>
                <th>日付</th>
                <th>名前</th>
                <th>出勤</th>
                <th>退勤</th>
                <th>休憩</th>
                <th>就業時間</th>
                <th>通常勤務</th>
                <th>残業時間</th>
                <th>備考</th>
                <th>ステータス</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="entry in sortedData" :key="entry.id" @click="handleRowClick(entry)">
                <td>{{ entry.date }}</td>
                <td>{{ entry.staff }}</td>
                <td v-if="entry.type === 'timecard'">{{ entry.data.time_start }}</td>
                <td v-if="entry.type === 'paid_leave'" colspan="7" style="text-align: center;">{{ '有給休暇' }}</td>
                <td v-if="entry.type === 'timecard'">{{ entry.data.time_stop }}</td>
                <td v-if="entry.type === 'timecard'">{{ entry.data.basic_break_hours }}</td>
                <td v-if="entry.type === 'timecard'">{{ entry.data.working_hours }}</td>
                <td v-if="entry.type === 'timecard'">{{ entry.working_hours }}</td>
                <td v-if="entry.type === 'timecard'">{{ entry.data.overtime_hours }}</td>
                <td v-if="entry.type === 'timecard'">
                  {{
                    entry.data.notes && entry.data.notes.length
                      ? (entry.data.notes.length <= 10
                        ? entry.data.notes
                        : entry.data.notes.slice(0, 10) + '...')
                      : ''
                  }}
                </td>
                <td>{{ entry.data.approval_status }}</td>
                <td v-if="entry.data.approval_status === '承認済み'"><button class="btn btn-danger btn-flat btn-sm" @click="confirmDelete(entry.type, entry.data.id)"><i class="fa fa-trash"></i></button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="row">
            <div class="col-md-4">
              <p>日付</p>
            </div>
            <div class="col-md-8">
              <p>{{ staff.date }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>名前</p>
            </div>
            <div class="col-md-8">
              <p>{{ staff.staff }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>店舗</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.shopName }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>勤務時間</p>
            </div>
            <div class="col-md-8">
              <p>{{ staff.regular_working_hours_start }} - {{ staff.regular_working_hours_stop }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>出勤</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.time_start }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>退勤</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.time_stop }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>休憩</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.basic_break_hours }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>就業時間</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.working_hours }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>通常の勤務時間</p>
            </div>
            <div class="col-md-8">
              <p>{{ staff.working_hours }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>残業時間</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.overtime_hours }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>備考</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.notes }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>位置情報（出勤）</p>
            </div>
            <div class="col-md-8">
              <p>
                <span v-if="timecard.time_start_latitude">
                  <a target="_blank" :href="'https://www.google.com/maps/search/'+timecard.time_start_latitude+',+'+timecard.time_start_longitude">{{ timecard.time_start_latitude }}, {{ timecard.time_start_longitude }}</a>
                </span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>位置情報（退勤）</p>
            </div>
            <div class="col-md-8">
              <p>
                <span v-if="timecard.time_stop_latitude">
                  <a target="_blank" :href="'https://www.google.com/maps/search/'+timecard.time_stop_latitude+',+'+timecard.time_stop_longitude">{{ timecard.time_stop_latitude }}, {{ timecard.time_stop_longitude }}</a>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal">キャンセル</button>
          <button type="button" class="btn btn-danger" @click="updateApproval(3, staff.type)">修正を依頼する</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-2">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="row">
            <div class="col-md-4">
              <p>日付</p>
            </div>
            <div class="col-md-8">
              <p>{{ staff.date }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>名前</p>
            </div>
            <div class="col-md-8">
              <p>{{ staff.staff }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>店舗</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.shopName }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>勤務時間</p>
            </div>
            <div class="col-md-8">
              <p>{{ staff.regular_working_hours_start }} - {{ staff.regular_working_hours_stop }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>出勤</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.time_start }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>退勤</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.time_stop }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>休憩</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.basic_break_hours }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>就業時間</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.working_hours }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>通常の勤務時間</p>
            </div>
            <div class="col-md-8">
              <p>{{ staff.working_hours }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>残業時間</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.overtime_hours }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>備考</p>
            </div>
            <div class="col-md-8">
              <p>{{ timecard.notes }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>位置情報（出勤）</p>
            </div>
            <div class="col-md-8">
              <p>
                <span v-if="timecard.time_start_latitude">
                  <a target="_blank" :href="'https://www.google.com/maps/search/'+timecard.time_start_latitude+',+'+timecard.time_start_longitude">{{ timecard.time_start_latitude }}, {{ timecard.time_start_longitude }}</a>
                </span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p>位置情報（退勤）</p>
            </div>
            <div class="col-md-8">
              <p>
                <span v-if="timecard.time_stop_latitude">
                  <a target="_blank" :href="'https://www.google.com/maps/search/'+timecard.time_stop_latitude+',+'+timecard.time_stop_longitude">{{ timecard.time_stop_latitude }}, {{ timecard.time_stop_longitude }}</a>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-primary" @click="updateApproval(2, staff.type)">承認する</button>
          <button type="button" class="btn btn-danger" @click="updateApproval(3, staff.type)">修正を依頼する</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-delete">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="row">
            <div class="col-md-12">
              <p>削除しますか︖</p>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-primary" @click="deleteRecord">削除する</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal">キャンセル</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      data: [],
      timecard: [],
      currentDate: new Date(),
      dateDisplayed : null,
      staff: [],
      shop: [],
      record: {
        id: null,
        type: null,
      },
      name: null,
      company_plan: 0,
      navigatedDate: this.$moment(),
    }
  },
  computed: {
    sortedData() {
      const allData = [];

      // Iterate through your data and push timecard and paid_leave data into allData
      this.data.forEach(entry => {
        if (entry.timecard && entry.timecard.length > 0) {
          entry.timecard.forEach(timecardEntry => {
            allData.push({ type: 'timecard', date: timecardEntry.date, staff: entry.name, regular_working_hours_start: entry.regular_working_hours_start, regular_working_hours_stop: entry.regular_working_hours_stop, working_hours: entry.regular_working_hours, data: timecardEntry });
          });
        }
        if (entry.paid_leaves && entry.paid_leaves.length > 0) {
          entry.paid_leaves.forEach(paidLeaveEntry => {
            allData.push({ type: 'paid_leave', date: paidLeaveEntry.date, staff: entry.name, regular_working_hours_start: entry.regular_working_hours_start, regular_working_hours_stop: entry.regular_working_hours_stop, working_hours: entry.regular_working_hours, data: paidLeaveEntry });
          });
        }
      });

      // Sort allData by date
      allData.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      });

      return allData;
    },
    isButtonDisabled() {
      if (this.company_plan === 0) {
        if (this.$moment().format('YYYY-MM-DD') > this.navigatedDate.format('YYYY-MM-DD')) {
          return true;
        } else {
          if (this.$moment().format('YYYY-MM-DD') > this.navigatedDate.format('YYYY-MM-05')) {
            return true;
          }
          return false;
        }
      } else {
        return false;
      }

    },
  },
  mounted() {
    this.getTimecard(this.currentDate.getFullYear()+'-'+(this.currentDate.getMonth()+1).toString().padStart(2, '0')+'-'+this.currentDate.getDate());
    this.dateDisplayed = this.currentDate.getFullYear() + '年' + (this.currentDate.getMonth() + 1) + '月';
  },
  methods: {
    navigate (direction) {
      this.currentDate.setMonth(this.currentDate.getMonth() + direction);
      this.navigatedDate = this.navigatedDate.clone().add(direction, 'months');
      this.dateDisplayed = this.currentDate.getFullYear() + '年' + (this.currentDate.getMonth() + 1) + '月';
      this.getTimecard(this.currentDate.getFullYear()+'-'+(this.currentDate.getMonth()+1).toString().padStart(2, '0')+'-'+this.currentDate.getDate());
    },
    getTimecard (date) {
      const shop_id = this.$store.getters.shop;
      this.$http.get(process.env.VUE_APP_API_PATH + '/shop/timecard/' + shop_id + '/' + date)
      .then(response => {
        this.data = response.data.data.staff;
        this.company_plan = response.data.data.company_plan;
      })
      .catch(error => {
        console.log(error);
      });
    },
    search (date) {
      if (this.name) {
        if (!date) {
          date = this.$moment().format('YYYY-MM-DD');
        }
        const shop_id = this.$store.getters.shop;
        this.$http.get(process.env.VUE_APP_API_PATH + '/shop/timecard/search/' + shop_id + '/' + date + '/' + this.name)
        .then(response => {
          this.data = response.data.data.staff;
        })
        .catch(error => {
          console.log(error);
        });
      } else {
        this.getTimecard(this.$moment().format('YYYY-MM-DD'));
      }
    },
    viewDetails () {
      this.$router.push({name:'CompanyShopDetailsPage'});
    },
    handleRowClick (data) {
      // console.log(data);
      this.staff = data;
      this.timecard = data.data;
      this.shop = data.shop;
      // const lastTD = event.target.parentNode.lastElementChild;
      // const lastTDValue = lastTD.innerText;
      if (this.staff.data.approval_status === '承認済み') {
        $("#modal-1").modal();
      } else if (this.staff.data.approval_status === '承認待ち') {
        $("#modal-2").modal();
      }
    },
    updateApproval (status, type) {
      const data = {
        approval_status: status
      };
      if (type === 'timecard') {
        this.$http.put(process.env.VUE_APP_API_PATH + '/company/timecard/' + this.timecard.id, data)
        .then(response => {
          if (response.data.success) {
            this.$toast.success(response.data.message);
            if (this.name) {
              this.search(this.currentDate.getFullYear()+'-'+(this.currentDate.getMonth()+1).toString().padStart(2, '0')+'-'+this.currentDate.getDate());
              return true;
            }
            this.getTimecard(this.currentDate.getFullYear()+'-'+(this.currentDate.getMonth()+1).toString().padStart(2, '0')+'-'+this.currentDate.getDate());
          }
        })
        .catch(error => {
          if (error.response) {
            const response = error.response.errors
            this.$toast.error(response);
          }
        });
      } else {
        this.$http.put(process.env.VUE_APP_API_PATH + '/shop/paidLeave/' + this.timecard.id, data)
        .then(response => {
          if (response.data.success) {
            this.$toast.success(response.data.message);
            if (this.name) {
              this.search(this.currentDate.getFullYear()+'-'+(this.currentDate.getMonth()+1).toString().padStart(2, '0')+'-'+this.currentDate.getDate());
              return true;
            }
            this.getTimecard(this.currentDate.getFullYear()+'-'+(this.currentDate.getMonth()+1).toString().padStart(2, '0')+'-'+this.currentDate.getDate());
          }
        })
        .catch(error => {
          if (error.response) {
            const response = error.response.errors
            this.$toast.error(response);
          }
        });
      }
      $("#modal-1").modal('hide');
      $("#modal-2").modal('hide');
    },
    deleteRecord () { //confirmDelete
      if (this.record.type === 'timecard') {
        this.$http.delete(process.env.VUE_APP_API_PATH + '/shop/timecard/' + this.record.id)
        .then(response => {
          this.$toast.success(response.data.message);
          $('#modal-delete').modal('hide');
          setTimeout(() => {
            this.getTimecard(this.currentDate.getFullYear()+'-'+(this.currentDate.getMonth()+1).toString().padStart(2, '0')+'-'+this.currentDate.getDate());
          }, 1000);
        })
        .catch(error => {
          console.log(error);
        });
      } else {
        this.$http.delete(process.env.VUE_APP_API_PATH + '/shop/paidLeave/' + this.record.id)
        .then(response => {
          this.$toast.success(response.data.message);
          $('#modal-delete').modal('hide');
          setTimeout(() => {
            this.getTimecard(this.currentDate.getFullYear()+'-'+(this.currentDate.getMonth()+1).toString().padStart(2, '0')+'-'+this.currentDate.getDate());
          }, 1000);
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
    confirmDelete (type, id) { 
      this.record.id = id;
      this.record.type = type;
      $('#modal-delete').modal();
    }
  },
};
</script>

<style scoped>
  .date-navigation {
    display: flex;
    align-items: center;
  }

  .arrow-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .date-text {
    margin: 0 10px;
    font-size: 18px;
    font-weight: bold;
  }
</style>