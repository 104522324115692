import { createStore } from 'vuex';
import Cookies from "js-cookie";

export default createStore({
  state() {
    return {
      token: null,
      permission: null,
      user_name: null,
      company: null,
      store: null,
    };
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    CLEAR_TOKEN(state) {
      state.token = null;
    },
    SET_USER_NAME(state, user_name) {
      state.user_name = user_name;
    },
    SET_PERMISSION(state, permission) {
      state.permission = permission;
    },
    CLEAR_PERMISSION(state) {
      state.permission = 0;
    },
    SET_COMPANY(state, company) {
      state.company = company;
    },
    SET_STORE(state, store) {
      state.store = store;
    },
  },
  actions: {
    async setStore ({commit}, data) {
      commit('SET_STORE', data.data.shop_id);
      const remember = data.data.remember;
      Cookies.set("store", data.data.shop_id, {
        expires: remember ? 365 : null,
      });
      return true;
    },
    async setCompany ({commit}, data) {
      commit('SET_COMPANY', data.data.company_id);
      const remember = data.data.remember;
      Cookies.set("company", data.data.company_id, {
        expires: remember ? 365 : null,
      });
      return true;
    },
    async setAuth({ commit }, data) {
      commit('SET_TOKEN', data.data.token);
      commit('SET_PERMISSION', data.data.access);
      commit('SET_USER_NAME', data.data.userName);
      const remember = data.data.remember;
      Cookies.set("token", data.data.token, {
        expires: remember ? 365 : null,
      });
      Cookies.set("access", data.data.access, {
        expires: remember ? 365 : null,
      });
      Cookies.set("userName", data.data.userName, {
        expires: remember ? 365 : null,
      });

      if (data.data.company_id) {
        this.dispatch('setCompany', data);
      }
      if (data.data.shop_id) {
        this.dispatch('setStore', data);
      }
      return true;
    },
    async logout({ commit }) {
      // await this.$axios.post(API.LOGOUT);
      commit("SET_TOKEN", null);
      Cookies.remove("token");
      Cookies.remove("access");
      Cookies.remove("userName");
      Cookies.remove("company");
      Cookies.remove("store");
      return true;
    },
  },
  getters: {
    isAuthenticated: state => !!state.token,
    token: state => state.token,
    userName: state => state.user_name,
    userPermission: state => state.permission,
    company: state => state.company,
    shop: state => state.store,
  },
});
