<template>
  <div class="card">
    <div class="card-body">
      <div class="form-group row">
        <span class="col-sm-1 col-form-span font-weight-bold">店舗名</span>
        <div class="col-sm-3">
          <p>{{ data.shop_name }}</p>
        </div>
        <span class="col-sm-1 col-form-span font-weight-bold">管理者</span>
        <div class="col-sm-3">
          <p v-for="(staff) in filteredStaff" :key="staff.id">
            {{ staff.name }}
          </p>
        </div>
        <span class="col-sm-1 col-form-span font-weight-bold">スタッフ数</span>
        <div class="col-sm-3">
          <p>{{ data.number_of_staff }}名</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-1 col-form-span font-weight-bold">勤務状況</span>
        <div class="col-sm-3">
          <span>{{ currentDate }}</span>
          <span class="ml-5">&nbsp;</span>
        </div>
      </div>
      <div class="row mt-5">

        <div v-for="(staff) in data.staff" :key="staff.id" class="col-md-3">
          <div class="card bg-default">
            <div class="card-body">
              <div class="text-center">{{ staff.name }}</div>
              <div class="mt-5 text-center">
                  <span class="float-left" :class="getTimecardInfo(staff).statusIn">{{ getTimecardInfo(staff).time_start }}</span>
                  <span v-if="getTimecardInfo(staff).paid_leave" class="text-warning">{{ getTimecardInfo(staff).paid_leave }}</span>
                  <span class="float-right" :class="getTimecardInfo(staff).statusOut">{{ getTimecardInfo(staff).time_stop }}</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        staff: [],
      },
      currentDate: new Date(),
    }
  },
  computed: {
    filteredStaff() {
      return this.data.staff.filter(staff => staff.pivot.manager_flag);
    },
    getTimecardInfo() {
      return (staff) => {
        const info = {
          paid_leave: staff.paid_leaves[0] ? '有給休暇' : null,
          time_start: staff.timecard[0]?.time_start? staff.timecard[0].time_start : '---',
          time_stop: staff.timecard[0]?.time_stop? staff.timecard[0].time_stop : staff.timecard[0]?.time_start ? '未' : '---',
          statusIn: staff.timecard[0]?.timeInStatus ? 'text-primary' : staff.timecard[0] ? 'text-warning': '',
          statusOut: staff.timecard[0]?.timeOutStatus ? 'text-primary' : staff.timecard[0] ? 'text-warning': '',
        };
        return info;
      };
    },
  },
  mounted() {
    this.currentDate = this.currentDate.getFullYear() + '-' + (this.currentDate.getMonth()+1) + '-' + this.currentDate.getDate();
    this.getShopDetails();
  },
  methods: {
    getShopDetails () {
      const shop_id = this.$store.getters.shop;
      this.$http.get(process.env.VUE_APP_API_PATH + '/shop/timecardList/' + shop_id + '/' + this.currentDate)
      .then(response => {
        this.data = response.data.data;
        this.data.number_of_staff = this.data.staff.length;
      })
      .catch(error => {
        console.log(error);
      });
    },
  },
};
</script>

<style scoped>
  .bg-warning {
      color: #FFF !important;
  }
</style>