<template>
  <div class="card">
    <div class="card-header">
      <button class="btn btn-sm btn-success float-right" @click="this.$router.push({name:'CompanyShopRegisterPage'});">＋店舗を登録する</button>
      <div>
        <div class="input-group input-group-sm" style="width: 200px;">
          <input type="text" v-model="shopName" class="form-control" placeholder="検索">

          <div class="input-group-append">
            <button type="button" class="btn btn-default" @click="searchShop">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body table-responsive p-0">
      <table class="table table-hover text-nowrap">
        <thead>
          <tr>
            <th>店舗名</th>
            <th>管理者</th>
            <th>スタッフ数</th>
            <th>詳細</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(shop) in shops" :key="shop.id">
            <td><a>{{ shop.shop_name }}</a></td>
            <td>
            <p v-for="(staff) in filteredStaff(shop)" :key="staff.id">
              {{ staff.name }}
            </p>
          </td>
            <td>{{ shop.staff.length }}</td>
            <td><button class="btn btn-warning" @click="viewDetails(shop.id)">詳細</button></td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- /.card-body -->
  </div>
</template>

<script>
export default {
  name: 'CompanyShopPage',
  data () {
    return {
      shops: [],
      shopName: null,
    }
  },
  computed: {
    filteredStaff() {
      return (shop) => {
        return shop.staff.filter((staff) => staff.pivot.manager_flag);
      };
    },
  },
  mounted() {
    this.getShopList();
  },
  methods: {
    viewDetails (param) {
      this.$router.push({name:'CompanyShopDetailsPage', params: {shop_id: param}});
    },
    getShopList () {
      const company_id = this.$store.getters.company;
      this.$http.get(process.env.VUE_APP_API_PATH + '/company/shopList/'+company_id)
      .then(response => {
        if (response.data.success) {
          this.shops = response.data.data;
        }
      })
      .catch(error => {
        if (error.response) {
          this.$toast.error(error.response.data.message);
        }
      });
    },
    searchShop () {
      const company_id = this.$store.getters.company;
      this.$http.get(process.env.VUE_APP_API_PATH + '/company/shopSearch/'+company_id+'/'+this.shopName)
      .then(response => {
        if (response.data.success) {
          this.shops = response.data.data;
        }
      })
      .catch(error => {
        if (error.response) {
          this.$toast.error(error.response.data.message);
        }
      });
    },
  },
};
</script>

<style scoped>

</style>