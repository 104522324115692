<template>
  <div class="card">
    <div v-if="company" class="card-body">
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">ID</span>
        <div class="col-sm-8">
          <p>{{ company.id }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">プラン</span>
        <div class="col-sm-8">
          <p style="color: #47AC24;">{{ company.plan }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">ステータス</span>
        <div class="col-sm-8">
          <p style="color: #0019FF;">{{ company.status }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">会社名</span>
        <div class="col-sm-8">
          <p>{{ company.company_name }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">法人番号</span>
        <div class="col-sm-8">
          <p>{{ company.corporate_number }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">代表者名</span>
        <div class="col-sm-8">
          <p>{{ company.representative_name }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">担当者名</span>
        <div class="col-sm-8">
          <p>{{ filteredCompanyDetails.staff_name ? filteredCompanyDetails.staff_name: '&nbsp;' }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">代表店舗</span>
        <div class="col-sm-8">
          <p>{{ filteredCompanyDetails.shop_name }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">所有店舗</span>
        <div class="col-sm-8">
          <p v-for="(shop) in company.shops" :key="shop.id">{{ shop.shop_name }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">郵便番号</span>
        <div class="col-sm-8">
          <p>{{ company.postal_code }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">住所</span>
        <div class="col-sm-8">
          <p>{{ company.address }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">電話番号</span>
        <div class="col-sm-8">
          <p>{{ company.phone_number }}</p>
        </div>
      </div>
      <div class="form-group row">
        <span class="col-sm-2 offset-sm-1 col-form-span font-weight-bold">URL</span>
        <div class="col-sm-8">
          <p>{{ company.url }}</p>
        </div>
      </div>
    </div>
    <div class="card-footer row">
      <div class="col-sm-4 offset-sm-2">
        <button class="btn btn-primary btn-block" @click="edit">編集</button>
      </div>
      <div class="col-sm-4">
        <button class="btn btn-default btn-block">キャンセル</button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      company: {
        shops: [],
        staff: [],
      },
    }
  },
  computed: {
    filteredCompanyDetails() {
      if (!this.company) {
        return {};
      }

      const shop = this.company.shops.find(shop => shop.id === this.company.shop_id);
      const staff = this.company.staff.find(staff => staff.id === this.company.contact_person_id);

      console.log(shop);

      return {
        shop_name: shop ? shop.shop_name : 'N/A',
        staff_name: staff ? staff.name : 'N/A',
      };
    },
  },
  mounted () {
    this.getCompanyDetails();
  },
  methods: {
    getCompanyDetails () {
      this.$http.get(process.env.VUE_APP_API_PATH + '/admin/company/' + this.$store.getters.company)
      .then(response => {
        this.company = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    dialog() {
      $('#modal-default').modal();
    },
    edit() {
      this.$router.push({name:'CompanyEditPage2', params: {company_id: this.$store.getters.company}});
    },
  },
};
</script>

<style scoped>

</style>