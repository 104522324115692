<template>
  <!-- Navbar -->
  <nav :class="navbarClass">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button">
          <i class="fas fa-bars"></i>
          
        </a>
      </li>
      
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Navbar Search -->
      <li class="nav-item dropdown user-menu">
        <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
          <span class="d-none d-md-inline">ソフトネクスト</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <!-- User image -->
          <li :class="navbarDropDown">
              <p>{{ userName }}</p>
          </li>
          <!-- Menu Footer-->
          <li class="user-footer">
            <!-- <a href="#" class="btn btn-default btn-flat">Profile</a> -->
            <button class="btn btn-default btn-flat float-right" @click="logout">ログアウト</button>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'NavBar',
  data () {
    return {
      permission: null,
    }
  },
  computed: {
    ...mapGetters(['userName', 'userPermission']),
    navbarClass() {
      return {
        'main-header navbar navbar-expand navbar-primary navbar-light': this.userPermission === 1,
        'main-header navbar navbar-expand navbar-success navbar-light': this.userPermission === 2,
        'main-header navbar navbar-expand navbar-warning navbar-light': this.userPermission === 3,
      };
    },
    navbarDropDown() {
      return {
        'user-header bg-primary': this.userPermission === 1,
        'user-header bg-success': this.userPermission === 2,
        'user-header bg-warning': this.userPermission === 3,
      };
    },
  },
  mounted () {
    
  },
  methods: {
    logout () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push({ name: 'Login' });
      })
      // console.log(this.$store.dispatch('logout'));
    },
  }
}
</script>

<style scoped>

  span {
    color: #FFF !important;
  }

  .bg-warning {
    color: #FFF !important;
  }

  .user-header {
    height: 70px !important;
  }
</style>