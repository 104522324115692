<template>
  <footer class="main-footer">
    <strong>Copyright &copy; {{ currentYear }} <a href="#">SoftNext.co.jp</a></strong>
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <b>Version</b> 3.1.0
    </div>
  </footer>
</template>

<script>
export default {
  data () {
    return {
      currentYear: this.$moment().format('YYYY'),
    }
  }
}
</script>

<style>

</style>