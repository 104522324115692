<template>
  <div class="card">
    <form ref="shopForm">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-sm-2 offset-sm-1 col-form-label ">店舗名<span class="required ml-3">*</span></label>
          <div class="col-sm-8">
            <input v-model="formData.shop_name" type="text" class="form-control" required>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 offset-sm-1 col-form-label ">スタッフ数</label>
          <div class="col-sm-8">
            <p>{{ formData.number_of_staff ? formData.number_of_staff : 0 }}名</p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 offset-sm-1 col-form-label ">郵便番号<span class="required ml-3">*</span></label>
          <div class="col-sm-8">
            <input v-model="formData.postal_code" placeholder="ハイフンなし整数で入力してください" class="form-control" required @keypress="validateNumber">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 offset-sm-1 col-form-label ">住所<span class="required ml-3">*</span></label>
          <div class="col-sm-8">
            <textarea v-model="formData.address" class="form-control" cols="30" rows="3" required></textarea>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 offset-sm-1 col-form-label ">電話番号<span class="required ml-3">*</span></label>
          <div class="col-sm-8">
            <input v-model="formData.tel_number" placeholder="ハイフンなし整数で入力してください" class="form-control" required @keypress="validateNumber">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 offset-sm-1 col-form-label ">URL</label>
          <div class="col-sm-8">
            <input v-model="formData.url" type="text" class="form-control" required>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 offset-sm-1 col-form-label ">タイムカード調整</label>
          <div class="col-sm-1">
            <div class="form-check mb-3">
              <input type="checkbox" class="form-check-input" v-model="time_adjustment">
              <label class="form-check-label time_adjustment" for="time_adjustment">利⽤する</label>
            </div>
          </div>
          <div class="col-sm-1" v-if="time_adjustment">
            <div class="form-check mb-3">
              <select v-model="formData.time_adjustment" class="form-control form-control-sm" placeholder="分" required>
                <option value="5">5</option>
                <option value="15">15</option>
                <option value="30">30</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer row">
        <div class="col-sm-4 offset-sm-2">
          <button type="button" class="btn btn-success btn-block" @click="dialog">登録</button>
        </div>
        <div class="col-sm-4">
          <button type="button" class="btn btn-default btn-block" @click="this.$router.push({name:'CompanyShopPage'});">キャンセル</button>
        </div>
      </div>
    </form>
  </div>

  <div class="modal fade" id="modal-default">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>{{ formData.shop_name }}を登録します。</p>
          <p>よろしいですか？</p>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-success" @click="register">登録する</button>
          <button type="button" class="btn btn-default" data-dismiss="modal">キャンセル</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      formData: {
        shop_name: null,
        managers: [],
        number_of_staff: 0,
        postal_code: null,
        address: null,
        tel_number: null,
        url: null,
        company_id: this.$store.getters.company,
        time_adjustment: 0,
      },
      staff: [],
      time_adjustment: false,
    }
  },
  methods: {
    validateNumber(event) {
      const key = event.key;
      if (!/[0-9]/.test(key)) {
          event.preventDefault();
      }
    },
    dialog() {
      $('#modal-default').modal();
    },
    dialogClose() {
      $('#modal-default').modal('hide');
    },
    register () {
        this.$http.post(process.env.VUE_APP_API_PATH + '/company/shop', this.formData)
        .then(response => {
          if (response.data.success) {
            this.$toast.success(response.data.message);
            setTimeout(() => {
              this.$router.push({ name: 'CompanyShopPage' });
            }, 1500);
          }
        })
        .catch(error => {
          if (error.response) {
            const response = error.response.data;
            if (error.response.status === 422) {
              this.$toast.error('未入力の必須項目があります');
            } else {
              this.$toast.error(response.message);
            }
          }
        });
      this.dialogClose();
    },
  },
  mounted() {
    $(".select2").select2({
      language: {
        noResults: function() {
          return "まずはスタッフ⼊⼒を⾏ってください"; // Change this to your desired message
        }
      }
    });
    $(".select2bs4").select2({
      theme: "bootstrap4",
    });
  }
};
</script>

<style scoped>
  .required {
    font-size: 18px;
    color: #ff0000;
  }

  input[type="checkbox"] {
    scale: 1.7;
  }
</style>