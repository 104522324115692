<template>
  <!-- Content Header (Page header) -->
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">{{ pageTitle }} <small>{{ pageSubTitle }}</small></h1>
              </div>
              <!-- /.col -->
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active">{{ pageTitle }}</li>
                </ol>
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->
          </div>
          <!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
</template>

<script>
export default {
  computed: {
    pageTitle() {
      // Access the route meta information and retrieve the title
      return this.$route.meta.title || '';
    },
    pageSubTitle() {
      // Access the route meta information and retrieve the title
      return this.$route.meta.subTitle || '';
    },
  },
}
</script>

<style>

</style>