<template>
  <!-- <body class="hold-transition sidebar-mini layout-fixed layout-navbar-fixed"> -->
    <div class="wrapper">
      <Navbar></Navbar>
      <Sidebar></Sidebar>
      <div class="content-wrapper">
        <ContentHeader></ContentHeader>
        <section class="content">
          <div class="container-fluid">
            <router-view />
          </div>
        </section>
      </div>
      <Footer></Footer>
      <ControlSidebar></ControlSidebar>
    </div>
  <!-- </body> -->
</template>

<script>
import "../../node_modules/admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css"
import "../../node_modules/admin-lte/plugins/select2/css/select2.min.css"

import ContentHeader from "../dashboard/ContentHeader.vue"
import Navbar from "../dashboard/NavBar.vue";
import Sidebar from "../dashboard/SideBar.vue";
import Footer from "../dashboard/MainFooter.vue";
import ControlSidebar from "../dashboard/ControlSidebar.vue"

export default {
  name: 'App',
  components: {
    ContentHeader,
    Navbar,
    Sidebar,
    Footer,
    ControlSidebar
  },
}
</script>

<style>
</style>
